define('command-center/pods/components/select-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selected: Ember.computed('value', 'column.options', function () {
      return this.column.options.findBy('label', this.value);
    }),

    click: function click(e) {
      e.stopPropagation();
    }
  });
});