define('command-center/pods/components/svg-hexagon/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: 'style',

    tagName: 'svg',

    backgroundColor: '#03618c',

    strokeColor: '#04a9f4',

    strokeWidth: '4',

    style: 'width: 84px; height: 94px;',

    textColor: '#ffffff'
  });
});