define('command-center/pods/campaigns/new/campaign-form/component', ['exports', 'command-center/pods/campaigns/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    campaignInfoComponent: 'campaigns/new/campaign-form/campaign-info',
    campaignTypeComponent: 'campaigns/new/campaign-form/campaign-type',
    budgetComponent: 'campaigns/new/campaign-form/budget',
    channelsComponent: 'campaigns/new/campaign-form/channels',
    creativeAssetsComponent: 'campaigns/new/campaign-form/creative-assets',
    additionalInfoComponent: 'campaigns/new/campaign-form/additional-info',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.setProperties(this, {
        companies: Ember.A()
      });
    },
    shouldContinue: function shouldContinue(campaign, currentStep) {
      var currentFields = _constants.default.CAMPAIGN_FORM_STEPS[currentStep];
      var validations = currentFields.map(function (field) {
        return campaign.validate(field);
      });
      var p = Ember.RSVP.all(validations);

      function resultFilter(result) {
        return (typeof result === 'undefined' ? 'undefined' : _typeof(result)) == "object" && result.validation && result.validation.length;
      }

      return p.then(function (results) {
        if (results.filter(resultFilter).length) {
          throw "Invalid";
        }
      });
    },


    actions: {
      checkErrors: function checkErrors(errors, currentStep) {
        if (!currentStep) {
          return true;
        }
        var currentFields = _constants.default.CAMPAIGN_FORM_STEPS[currentStep];
        return currentFields.find(function (field) {
          return errors.findBy('key', field);
        });
      }
    }
  });
});