define('command-center/mirage/config', ['exports', 'ember-cli-mirage', 'ember-file-upload/mirage'], function (exports, _emberCliMirage, _mirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.timing = 400;

    this.namespace = '/api';

    this.passthrough('https://sentry.io/**');

    this.get('/logout', function () {});

    this.post('/authenticate', function (schema, request) {
      var req = JSON.parse(request.requestBody);
      var key = req.api_key;
      var secret = req.api_secret;

      if (secret === 'secret') {
        return new _emberCliMirage.default.Response(201, {}, { jwt: 'hotdog-' + key });
      } else {
        return new _emberCliMirage.default.Response(401, {}, {});
      }
    });

    this.get('/users/me', function (schema, request) {
      var authorization = request.requestHeaders.authorization;
      if (authorization.includes('JWT hotdog')) {
        var email = authorization.split('-')[1];
        var user = this.serialize(schema.users.findBy({ email: email }));
        // const companies = this.serialize(schema.companies.all());

        return _extends({}, user, {
          meta: { company_id: 1, total_records: 1, total_pages: 1 }
        });
      } else {
        return new _emberCliMirage.default.Response(401, {}, {});
      }
    });

    this.get('/users', function (schema, request) {
      var params = request.queryParams;
      var filter = params.companyId ? { companyIds: [params.companyId] } : {};

      for (var key in params) {
        if ('page[number],page[size],sort,dir,companyId'.indexOf(key) === -1) {
          filter[key] = params[key];
        }
      }

      var rows = schema.users.all();
      var _request$queryParams = request.queryParams,
          sort = _request$queryParams.sort,
          dir = _request$queryParams.dir;

      var page_number = Number(request.queryParams['page[number]'] || 1);
      var page_size = Number(request.queryParams['page[size]'] || 20);

      dir = dir || 'asc';

      if (sort) {
        rows = rows.sort(sort);
        if (dir !== 'asc') {
          rows = rows.reverse();
        }
      }

      var offset = (page_number - 1) * page_size;
      rows = rows.slice(offset, offset + page_size);

      return rows;
    });

    this.post('/users');

    this.get('/users/:id');

    this.patch('/users/:id');

    this.get('/companies', function (_ref, request) {
      var companies = _ref.companies;

      var params = request.queryParams;
      if (params['filter[type]']) {
        return companies.where({ type: params['filter[type]'] });
      }
      return companies.all();
    });

    this.post('/companies');

    this.get('/companies/:id');

    this.patch('/companies/:id');

    this.get('/roles', function (schema, request) {
      var params = request.queryParams;
      if (params.companyId) {
        var company = schema.company.find(params.companyId);
        return company.roles.models;
      }
      return schema.roles.where(params);
    });

    this.get('/roles/:id');

    this.get('/files');

    this.get('/campaigns');

    this.get('/campaigns/:id');

    this.post('/campaigns', function (_ref2, request) {
      var campaigns = _ref2.campaigns,
          channels = _ref2.channels,
          lineItems = _ref2.lineItems;

      var campaignData = JSON.parse(request.requestBody).data;
      var company = campaignData.relationships.company;


      var campaignModel = campaigns.create(campaignData.attributes);
      campaignModel.companyId = company.data.id;

      var channelData = campaignData.relationships.channels.data;
      var channelModels = channelData.map(function (data) {
        var channelAttrs = _extends({}, data.attributes);
        var channel = channels.create(channelAttrs);
        channel.state = 'launching';
        var line_items = data.relationships.line_items;

        var lineItemModels = line_items.data.map(function (data) {
          var lineItemAtts = _extends({}, data.attributes);
          var lineItem = lineItems.create(lineItemAtts);
          var audience = data.relationships.audience;

          lineItem.channelId = channel.id;
          lineItem.audienceId = audience.data.id;
          return lineItem;
        });
        channel.lineItemIds = lineItemModels.map(function (lineItem) {
          return lineItem.id;
        });
        channel.save();
        return channel;
      });

      campaignModel.channelIds = channelModels.map(function (channel) {
        return channel.id;
      });
      campaignModel.save();
      return campaignModel;
    });

    this.get('/line_iutems');

    this.patch('/line_items/:id');

    this.get('/channels', function (schema, request) {
      var params = request.queryParams;
      if (params['filter[campaign_id]']) {
        var campaign = schema.campaigns.find(params['filter[campaign_id]']);
        return campaign.channels;
      }
      return schema.channels.where(params);
    });

    this.get('/channels/:id');

    this.get('/audiences', function (schema, request) {
      var params = request.queryParams;
      if (params['filter[channel_id]']) {
        var channel = schema.channels.find(params['filter[channel_id]']);
        return channel.audiences;
      }
      return schema.audiences.where(params);
    });

    this.post('/audiences');

    this.get('/channel_types');

    this.get('/placements');

    this.get('/actions');

    this.post('/upload', function () {
      return { file_token: 'abc123' };
    });

    this.get('/data_streams');

    this.post('/data_streams', function (_ref3, request) {
      var dataStreams = _ref3.dataStreams;

      var dataStreamData = JSON.parse(request.requestBody).data;
      var dataStreamModel = dataStreams.create(dataStreamData.attributes);

      dataStreamModel.save();

      return dataStreamModel;
    });

    this.get('/data_fields');

    this.get('/data');

    this.post('/upload_data_stream_sample_file', (0, _mirage.upload)(function (db, _ref4) {
      var file = _ref4.requestBody.file;
      var filename = file.name;
      // return { filename, size, token: '123abc', url };

      return {
        filename: filename,
        token: 'NDE.DgD-sA.4OkXjQM12fTTKyXJcmhJ0tC9LHQ',
        extra: {
          data_mapping_fields: [{ source_field_name: 'Campaign', data_field_id: null }, { source_field_name: 'Campaign ID', data_field_id: null }, { source_field_name: 'Site (DCM)', data_field_id: null }, { source_field_name: 'Placement', data_field_id: null }, { source_field_name: 'Date', data_field_id: null }, { source_field_name: 'Impressions', data_field_id: 2 }, { source_field_name: 'Clicks', data_field_id: null }, { source_field_name: 'Click Rate', data_field_id: null }, { source_field_name: 'Conversions', data_field_id: null }, { source_field_name: 'Video Completions', data_field_id: null }]
        }
      };
    }));

    this.post('/upload_campaign_additional_document', (0, _mirage.upload)(function (db, _ref5) {
      var file = _ref5.requestBody.file;
      var filename = file.name,
          size = file.size,
          url = file.url;

      return { filename: filename, size: size, token: '123abc', url: url };
    }));

    this.get('/dashboards');

    this.post('/dashboards');

    this.patch('/dashboards/:id');

    this.get('/facebook_campaigns');
  };

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };
});