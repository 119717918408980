define('command-center/pods/campaigns/new/campaign-form/campaign-type/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    summaryView: false,

    tagName: '',

    campaignTypeBackgroundColors: Ember.computed(function () {
      return ['#03618c', '#2A5585', '#4E457A', '#693970'];
    }),

    campaignTypeStrokeColors: Ember.computed(function () {
      return ['#04a9f4', '#4A94EA', '#907EE0', '#C86DD7'];
    }),

    groupClassNames: Ember.computed('summaryView', function () {
      return 'hs3 w-65 bh-light-gray' + (this.summaryView ? ' h-80' : '');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.setProperties(this, {
        campaignTypes: Ember.A([{ value: 'awareness', label: 'Awareness' }, { value: 'direct_response', label: 'Direct Response' }, { value: 'foot_traffic', label: 'Foot Traffic' }, { value: 'website_visits', label: 'Website Visits' }])
      });
    }
  });
});