define('command-center/pods/campaigns/new/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'command-center/validations/campaign'], function (exports, _authenticatedRouteMixin, _campaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.set(controller, 'CampaignValidations', _campaign.default);
    },


    actions: {
      createCampaign: function createCampaign() {
        var campaign = this.store.createRecord('campaign');
        this.controller.set('campaign', campaign);
      },
      saveCampaign: function saveCampaign(changeset) {
        var _this = this;

        var campaign = Ember.get(this, 'controller.campaign');
        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save();
          }
          throw 'Not valid';
        }).then(function () {
          campaign.channels.filter(function (channel) {
            return channel.isNew;
          }).forEach(function (record) {
            return record.deleteRecord();
          });
          _this.transitionTo('campaigns.campaign', campaign);
          Ember.set(_this, 'controller.campaign', null);
        });
      },
      deleteCampaign: function deleteCampaign() {
        var _this2 = this;

        Ember.get(this, 'controller.campaign').destroyRecord().then(function () {
          Ember.set(_this2, 'controller.campaign', null);
          _this2.transitionTo('dashboard');
        });
      }
    }
  });
});