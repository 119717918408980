define('command-center/pods/components/line-chart/legend/component', ['exports', 'command-center/pods/components/line-chart/legend/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: 'aside',

    _legendItems: Ember.computed('legendItems', function () {
      return this.legendItems.map(function (item) {
        return _extends({}, item, {
          borderColor: Ember.String.htmlSafe('border-color: ' + item.borderColor)
        });
      });
    })
  });
});