define('command-center/pods/dashboard/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var columns = [{
        valuePath: 'avatar.url',
        width: '4rem',
        cellComponent: 'avatar-cell'
      }, {
        label: 'Item',
        valuePath: 'label'
      }, {
        label: 'Status',
        valuePath: 'stateLabel',
        width: '7%'
      }, {
        label: 'Pace',
        valuePath: 'pace',
        cellComponent: 'percent-cell',
        width: '7%'
      }, {
        label: 'Budget',
        cellComponent: 'dashboard/budget-cell'
      }, {
        label: 'Units',
        cellComponent: 'dashboard/units-cell'
      }, {
        label: 'Start',
        valuePath: 'startDate',
        cellComponent: 'date-cell',
        width: '12%'
      }, {
        label: 'End',
        valuePath: 'endDate',
        cellComponent: 'date-cell',
        width: '12%'
      }];

      return Ember.RSVP.hash({
        columns: columns,
        companies: this.store.query('company', {
          'filter[type]': 'advertiser',
          include: 'campaigns'
        })
      });
    },


    actions: {
      fetchCampaignData: function fetchCampaignData(company) {
        return Ember.get(company, 'campaigns');
      },
      showCampaign: function showCampaign(row) {
        this.transitionTo('campaigns.campaign', Ember.get(row, 'content'));
      }
    }
  });
});