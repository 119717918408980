define('command-center/pods/components/date-cell/component', ['exports', 'ember-moment/computeds/from-now'], function (exports, _fromNow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['title'],
    title: (0, _fromNow.default)('value')
  });
});