define('command-center/pods/components/form-power-datepicker/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    placeholder: 'Select Date',
    format: 'MM/DD/YYYY',

    error: Ember.computed('form.model.error.@each', 'name', function () {
      var error = Ember.get(this, 'form.model.error');
      return error ? error[this.name] : null;
    }),

    minDate: Ember.computed('_minDate', 'form.model.changes.@each', 'format', function () {
      var changes = Ember.get(this, 'form.model.changes');
      var format = this.format;
      var _minDate = this._minDate;
      var date = void 0;

      if (!_minDate) {
        return null;
      } else if (_minDate === 'now') {
        date = (0, _moment.default)();
      } else if (!Ember.isEmpty(changes.filterBy('key', _minDate))) {
        _minDate = changes.filterBy('key', _minDate);
        date = (0, _moment.default)(Ember.get(_minDate, 'firstObject.value'));
      } else {
        date = (0, _moment.default)(_minDate);
      }
      return date.isValid() ? date.format(format) : null;
    }),

    maxDate: Ember.computed('_maxDate', 'form.model.changes.@each', 'format', function () {
      var changes = Ember.get(this, 'form.model.changes');
      var format = this.format;
      var _maxDate = this._maxDate;
      var date = void 0;

      if (!_maxDate) {
        return null;
      } else if (_maxDate === 'now') {
        date = (0, _moment.default)();
      } else if (!Ember.isEmpty(changes.filterBy('key', _maxDate))) {
        _maxDate = changes.filterBy('key', _maxDate);
        date = (0, _moment.default)(Ember.get(_maxDate, 'firstObject.value'));
      } else {
        date = (0, _moment.default)(_maxDate);
      }
      return date.isValid() ? date.format(format) : null;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var formItem = this.formItem;
      if (formItem) {
        Ember.setProperties(this, {
          name: formItem.name,
          label: formItem.label,
          required: formItem.required,
          verticalPosition: formItem.verticalPosition,
          format: formItem.format,
          allowEmpty: formItem.allowEmpty,
          _minDate: formItem.minDate,
          _maxDate: formItem.maxDate
        });
      }
    }
  });
});