define('command-center/pods/reset-password/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['token'],

    token: null,

    actions: {
      transitionToLogin: function transitionToLogin() {
        this.transitionToRoute('login');
      }
    }
  });
});