define('command-center/models/data-mapping-field', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    sourceFieldName: attr('string'),
    formula: attr('string'),
    placementIdentifier: attr('boolean'),

    dataField: belongsTo('data-field')
  });
});