define('command-center/pods/components/dashboard-item/component', ['exports', 'numeral'], function (exports, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformChartData = undefined;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var getLabel = function getLabel(dataField, fieldOptions) {
    var option = fieldOptions.find(function (option) {
      return option.dataFieldId == dataField.id;
    }) || {};
    return option.label || dataField.label;
  };

  var transformChartData = function transformChartData(data, _ref) {
    var dimensions = _ref.dimensions,
        metrics = _ref.metrics,
        fieldOptions = _ref.fieldOptions,
        _ref$hideEmpty = _ref.hideEmpty,
        hideEmpty = _ref$hideEmpty === undefined ? false : _ref$hideEmpty,
        _ref$usePercentages = _ref.usePercentages,
        usePercentages = _ref$usePercentages === undefined ? false : _ref$usePercentages;

    var dimension = dimensions.firstObject;
    var add = function add(p, c) {
      return p + c;
    };
    var hasData = function hasData(d) {
      return metrics.map(function (m) {
        return d[Ember.String.camelize(m.name)];
      }).reduce(add, 0);
    };
    var formatAsPercentage = function formatAsPercentage(value) {
      return (0, _numeral.default)(value).format('0.00%');
    };
    var filteredData = hideEmpty ? data.filter(hasData) : data;
    var totals = metrics.reduce(function (p, m) {
      p[m.id] = filteredData.mapBy(Ember.String.camelize(m.name)).reduce(add, 0);
      return p;
    }, {});
    fieldOptions = fieldOptions || [];

    return {
      labels: dimension ? filteredData.mapBy(Ember.String.camelize(dimension.name)).map(dimension.formatter) : [],
      datasets: metrics.map(function (metricDataField) {
        var reducer = metricDataField.reducer;
        var format = usePercentages ? formatAsPercentage : metricDataField.formatter;
        var values = usePercentages ? filteredData.map(function (d) {
          return d[Ember.String.camelize(metricDataField.name)] / totals[metricDataField.id];
        }) : filteredData.mapBy(Ember.String.camelize(metricDataField.name));

        return {
          name: metricDataField.name,
          label: getLabel(metricDataField, fieldOptions),
          labelValue: format(values.reduce(reducer, 0)),
          data: values,
          formattedData: values.map(format)
        };
      })
    };
  };

  exports.transformChartData = transformChartData;
  exports.default = Ember.Component.extend({
    dateRange: Ember.inject.service(),

    tagName: '',

    dataFields: Ember.computed('settings.{dimensions,metrics}', 'level', function () {
      var _this = this;

      return (this.settings.dimensions || []).filter(function (_, index) {
        return index === (_this.level || 0);
      }).concat(this.settings.metrics);
    }),

    filters: Ember.computed('settings.filters', 'dateRange.filter', function () {
      return _extends({}, this.dateRange.filter, this.settings.filters);
    }),

    groupings: Ember.computed('dataFields', 'settings.fieldOptions.@each.groupings', function () {
      var _this2 = this;

      return (this.settings.fieldOptions || []).reduce(function (groupings, fieldOption) {
        var dataField = _this2.dataFields.findBy('dataFieldId', fieldOption.id);
        var cleanedGroupings = (fieldOption.groupings || '').split(';').map(function (grouping) {
          return grouping.trim();
        }).join(';');
        var newGroupings = dataField && cleanedGroupings ? _defineProperty({}, 'groupings[' + dataField.name + ']', cleanedGroupings) : {};

        return _extends({}, groupings, newGroupings);
      }, {});
    }),

    transform: Ember.computed('settings.component', function () {
      var _this3 = this;

      var chartComponents = ['bar-chart', 'line-chart', 'horizontal-bar-chart', 'pie-chart'];
      var isChart = chartComponents.includes(this.settings.component);
      return isChart ? function (data) {
        return transformChartData.call(_this3, data, _this3.settings);
      } : function (data) {
        return data;
      };
    }),

    buildColumns: function buildColumns(_ref3) {
      var fieldOptions = _ref3.fieldOptions;

      return this.dataFields.map(function (dataField) {
        var label = getLabel(dataField, fieldOptions || []);
        return {
          label: label,
          valuePath: Ember.String.camelize(dataField.name || dataField),
          cellComponent: dataField.valueType === 'percentage' ? 'percent-cell' : dataField.valueType === 'currency' ? 'currency-cell' : dataField.valueType === 'integer' ? 'integer-cell' : 'formatter-cell',
          formatter: dataField.formatter
        };
      });
    },
    transformTableData: function transformTableData(data) {
      return data;
    }
  });
});