define('command-center/pods/components/from-now-cell/component', ['exports', 'ember-moment/computeds/format'], function (exports, _format) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['title'],
    title: (0, _format.default)('value', 'MMMM DD, YYYY')
  });
});