define('command-center/pods/campaigns/new/campaign-form/channels/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    actions: {
      addChannel: function addChannel(channel) {
        var model = Ember.get(this, 'form.model');
        Ember.get(model, 'channels').addObject(channel);
      }
    }
  });
});