define('command-center/pods/components/line-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    classNames: 'line-chart br3',

    headerComponent: 'line-chart/header',

    chartComponent: 'line-chart/chart',

    legendComponent: 'line-chart/legend',

    tooltipComponent: 'line-chart/tooltip',

    _data: Ember.computed('data.@each', '_visibleFields', function () {
      var _this = this;

      if (this._hasNoData()) {
        return null;
      }

      var chartData = _extends({}, this.data, {
        datasets: this.data.datasets.map(function (dataset, index) {
          var color = ['#ffffff', '#f5a623', '#65c9f7', '#b8e986'][index % 4];

          return _extends({}, dataset, {

            backgroundColor: color,
            borderColor: color,
            pointBackgroundColor: color,
            pointBorderColor: color,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: 'rgba(255,255,255,0.25)',
            pointHoverBorderWidth: '7',
            fill: false,
            lineTension: 0,
            borderWidth: 1,
            hidden: !_this._visibleFields.includes(dataset.name)
          });
        })
      });

      var maxDataValue = Math.max.apply(Math, _toConsumableArray(chartData.datasets.map(function (dataset) {
        return Math.max.apply(Math, _toConsumableArray(dataset.data));
      })));
      chartData.datasets = chartData.datasets.map(function (dataset) {
        var localMax = Math.max.apply(Math, _toConsumableArray(dataset.data));
        var scale = maxDataValue / localMax;
        return _extends({}, dataset, {
          data: dataset.data.map(function (value) {
            return value * scale;
          })
        });
      });

      return chartData;
    }),

    _defaultOptions: Ember.computed(function () {
      var _this2 = this;

      var tooltipElID = this.tooltipEl ? this.tooltipEl.id : '';
      var options = this.options || {};
      var setupTooltip = function setupTooltip(value, label) {
        _this2.set('tooltipValue', value);
        _this2.set('tooltipLabel', label);
      };

      return _extends({
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontStyle: '300',
              fontColor: '#ffffff'
            },
            gridLines: {
              color: 'rgba(255, 255, 255, 0.3)'
            }
          }],
          yAxes: [{
            ticks: {
              display: false,
              min: 0
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: { display: false },
        layout: {
          padding: {
            top: 40,
            left: 30,
            bottom: 20
          }
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          mode: 'nearest',

          intersect: false,

          callbacks: {
            title: function title(tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label;
            },
            label: function label(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].formattedData[tooltipItem.index];
            }
          },

          custom: function custom(tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById(tooltipElID);

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set Text
            if (tooltipModel.body) {
              setupTooltip(tooltipModel.body[0].lines[0], tooltipModel.title[0]);
            }

            // Display, position, and set styles for font
            Ember.run.later(function () {
              tooltipEl.style.display = 'grid';
              tooltipEl.style.opacity = 1;
              tooltipEl.style.transform = 'translate(\n              ' + (tooltipModel.caretX - tooltipEl.clientWidth / 2) + 'px,\n              ' + (tooltipModel.caretY - tooltipEl.clientHeight - 20) + 'px\n            )';
            });
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false
        }
      }, options);
    }),

    _fields: Ember.computed('data.@each', function () {
      var _this3 = this;

      return (this.datasets || []).filter(function (field) {
        return _this3.data.datasets && _this3.data.datasets.findBy('name', field.name).data.filter(function (d) {
          return d !== null;
        }).length;
      });
    }),

    _legendItems: Ember.computed('_data', function () {
      if (!this._data) {
        return [];
      }

      return this._data.datasets.map(function (dataset) {
        return {
          name: dataset.name,
          label: dataset.labelValue,
          value: dataset.label,
          borderColor: dataset.borderColor
        };
      });
    }),

    _visibleFields: Ember.computed('visibleFields', '_fields', function () {
      return this.visibleFields || this._fields.mapBy('name');
    }),

    _hasNoData: function _hasNoData() {
      return !Ember.get(this, 'data.datasets.length') || !this.data.datasets.reduce(function (total, dataset) {
        return total + dataset.data.length;
      }, 0);
    },
    getChart: function getChart() {
      return this.element.getElementsByTagName('canvas')[0].getBoundingClientRect();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // eslint-disable-next-line no-undef
      Chart.defaults.global.defaultFontFamily = "'Roboto'";

      var tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip-' + this.elementId;
      tooltipEl.classList.add(this.styleNamespace + '-tooltip', 'vs0', 'ph4', 'pv1', 'tc', 'br2', 'bg-white', 'o-0');

      // `this` will be the overall tooltip
      document.body.appendChild(tooltipEl);
      Ember.set(this, 'tooltipEl', tooltipEl);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var chart = void 0;

      try {
        chart = this.getChart();
      } catch (e) {
        return;
      }

      this.tooltipEl.style.position = 'absolute';
      this.tooltipEl.style.top = chart.top + window.scrollY + 'px';
      this.tooltipEl.style.left = chart.left + 'px';
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      document.body.removeChild(this.tooltipEl);
    }
  });
});