define('command-center/pods/components/form-inputmask/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    error: Ember.computed('form.model.error.@each', 'name', function () {
      var error = Ember.get(this, 'form.model.error');
      return error ? error[this.name] : null;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var formItem = this.formItem;
      if (formItem) {
        Ember.setProperties(this, {
          name: formItem.name,
          label: formItem.label,
          required: formItem.required,
          mask: formItem.mask
        });
      }
    }
  });
});