define('command-center/services/date-range', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Service.extend({
    value: null,

    options: Ember.computed(function () {
      return [{ label: 'All time', value: null }, {
        label: 'Today',
        value: {
          startDate: (0, _moment.default)(),
          endDate: (0, _moment.default)()
        },
        divider: true
      }, {
        label: 'Yesterday',
        value: {
          startDate: (0, _moment.default)().subtract(1, 'days'),
          endDate: (0, _moment.default)().subtract(1, 'days')
        }
      }, {
        label: 'Week-to-date',
        value: {
          startDate: (0, _moment.default)().startOf('week'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Month-to-date',
        value: {
          startDate: (0, _moment.default)().startOf('month'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Quarter-to-date',
        value: {
          startDate: (0, _moment.default)().startOf('quarter'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Year-to-date',
        value: {
          startDate: (0, _moment.default)().startOf('year'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Previous week',
        value: {
          startDate: (0, _moment.default)().subtract(7, 'days').startOf('week'),
          endDate: (0, _moment.default)().startOf('week').subtract(1, 'days')
        },
        divider: true
      }, {
        label: 'Previous month',
        value: {
          startDate: (0, _moment.default)().startOf('month').subtract(1, 'days').startOf('month'),
          endDate: (0, _moment.default)().startOf('month').subtract(1, 'days')
        }
      }, {
        label: 'Previous quarter',
        value: {
          startDate: (0, _moment.default)().startOf('quarter').subtract(1, 'days').startOf('quarter'),
          endDate: (0, _moment.default)().startOf('quarter').subtract(1, 'days')
        }
      }, {
        label: 'Previous year',
        value: {
          startDate: (0, _moment.default)().startOf('year').subtract(1, 'days').startOf('year'),
          endDate: (0, _moment.default)().startOf('year').subtract(1, 'days')
        }
      }, {
        label: 'Last 7 days',
        value: {
          startDate: (0, _moment.default)().subtract(7, 'days'),
          endDate: (0, _moment.default)()
        },
        divider: true
      }, {
        label: 'Last 14 days',
        value: {
          startDate: (0, _moment.default)().subtract(14, 'days'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Last 30 days',
        value: {
          startDate: (0, _moment.default)().subtract(30, 'days'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Last 90 days',
        value: {
          startDate: (0, _moment.default)().subtract(90, 'days'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Last 365 days',
        value: {
          startDate: (0, _moment.default)().subtract(365, 'days'),
          endDate: (0, _moment.default)()
        }
      }, {
        label: 'Last 24 months',
        value: {
          startDate: (0, _moment.default)().subtract(24, 'months'),
          endDate: (0, _moment.default)()
        },
        divider: true
      }];
    }),

    endDate: Ember.computed('value.value', function () {
      return this.value && this.value.value ? this.value.value.endDate.format('YYYY-MM-DD') : null;
    }),

    startDate: Ember.computed('value.value', function () {
      return this.value && this.value.value ? this.value.value.startDate.format('YYYY-MM-DD') : null;
    }),

    filter: Ember.computed('startDate', 'endDate', function () {
      var startDateFilter = this.startDate ? {
        'date_gte': this.startDate
      } : {};
      var endDateFilter = this.endDate ? {
        'date_lte': this.endDate
      } : {};

      return _extends({}, startDateFilter, endDateFilter);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.value = this.options.findBy('label', 'Last 30 days');
    }
  });
});