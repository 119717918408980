define('command-center/mirage/factories/datum', ['exports', 'ember-cli-mirage', 'moment'], function (exports, _emberCliMirage, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _faker$list;

  exports.default = _emberCliMirage.Factory.extend({
    targetingStrategy: (_faker$list = _emberCliMirage.faker.list).random.apply(_faker$list, ['behavioral', 'retargeting', 'footprint']),

    clicks: function clicks() {
      return parseInt(Math.random() * 200);
    },
    conversions: function conversions() {
      var random = Math.random() * 200 - 100;
      return this.clicks + (Math.abs(random) < this.clicks ? random : 5);
    },
    ctr: function ctr() {
      var random = Math.random() * 200 - 100;
      var randomClicks = this.clicks + (Math.abs(random) < this.clicks ? random : 5);
      return randomClicks / 200 / 10;
    },
    date: function date() {
      var date = _emberCliMirage.faker.date.past();
      return (0, _moment.default)(date.toISOString()).format('YYYY-MM-DD');
    },
    impressions: function impressions() {
      var random = Math.random() * 200 - 100;
      return this.clicks + (Math.abs(random) < this.clicks ? random : 5);
    },
    influencedVisits: function influencedVisits() {
      var random = Math.random() * 200 - 100;
      return this.clicks + (Math.abs(random) < this.clicks ? random : 5);
    },
    placement: function placement() {
      return Ember.String.underscore(_emberCliMirage.faker.commerce.productName());
    },
    revenue: function revenue() {
      return parseInt(Math.random() * 3000);
    }
  });
});