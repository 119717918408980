define('command-center/models/campaign', ['exports', 'ember-data', 'command-center/pods/campaigns/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    label: attr('string'),
    landingPageUrl: attr('string'),
    spend: attr('number'),
    budget: attr('number'),
    actualUnits: attr('number'),
    units: attr('number'),
    startDate: attr('string'),
    endDate: attr('string'),
    targetLocations: attr('string'),
    pixelTracking: attr('boolean'),
    // conversionAction: attr('string'),
    additionalNotes: attr('string'),
    pace: attr('string'),
    paceType: attr('string'),
    vertical: attr('string'),
    state: attr('string'),
    type: attr('string'),
    additionalDocuments: attr(),

    company: belongsTo('company'),
    channels: hasMany('channel', { serialize: true, embed: true }),

    verticalLabel: Ember.computed('vertical', function () {
      var _this = this;

      return _constants.default.VERTICALS.find(function (vertical) {
        return vertical.value == _this.vertical;
      }).label;
    }),

    stateLabel: Ember.computed('state', function () {
      var _this2 = this;

      return _constants.default.CAMPAIGN_STATES.find(function (campaign) {
        return campaign.value == _this2.state;
      }).label;
    })
  });
});