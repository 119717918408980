define('command-center/models/placement', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    adName: attr('string'),
    selected: attr('boolean', { defaultValue: true }),
    isSelected: attr('boolean', { defaultValue: true })
  });
});