define('command-center/models/data-stream', ['exports', 'ember-data', 'command-center/pods/campaigns/campaign/data-streams/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    dataSource: attr('string'),
    email: attr('string'),
    facebookCampaigns: attr('string'),
    frequency: attr('number'),
    deliveryType: attr('string'),
    sampleFile: attr(),
    latestDataSetState: attr('string'),
    state: attr('string'),
    type: attr('string'),

    campaign: belongsTo('campaign'),
    dataMappingFields: hasMany('data-mapping-field', { serialize: true, embed: true }),

    dataSourceLabel: Ember.computed('type', function () {
      var _this = this;

      return _constants.default.DATA_SOURCES.find(function (dataSource) {
        return dataSource.value == _this.dataSource;
      }).label;
    }),

    frequencyLabel: Ember.computed('frequency', function () {
      var _this2 = this;

      return _constants.default.FREQUENCIES.find(function (frequency) {
        return frequency.value == _this2.frequency;
      }).label;
    }),

    deliveryTypeLabel: Ember.computed('deliveryType', function () {
      var _this3 = this;

      return _constants.default.DELIVERY_TYPES.find(function (deliveryType) {
        return deliveryType.value == _this3.deliveryType;
      }).label;
    }),

    typeLabel: Ember.computed('type', function () {
      var _this4 = this;

      return _constants.default.TYPES.find(function (type) {
        return type.value == _this4.type;
      }).label;
    })
  });
});