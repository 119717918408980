define('command-center/pods/components/countdown/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    timeout: null,

    hours: Ember.computed('minutes', function () {
      return 24 - (0, _moment.default)().hour();
    }),

    minutes: Ember.computed('seconds', function () {
      return 60 - (0, _moment.default)().minute();
    }),

    seconds: Ember.computed(function () {
      return (0, _moment.default)().second() === 0 ? 59 : 60 - (0, _moment.default)().second() - 1;
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var secondsUpdated = function secondsUpdated() {
        _this.notifyPropertyChange('seconds');
        _this.set('timeout', setTimeout(secondsUpdated, 100));
      };
      secondsUpdated();
    },
    willDestroyElement: function willDestroyElement() {
      clearTimeout(this.timeout);
    }
  });
});