define('command-center/pods/components/dashboard-item-settings-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    didReceiveAttrs: function didReceiveAttrs() {
      var options = this.extra.fieldOptions ? this.extra.fieldOptions.findBy('dataFieldId', this.option.id) : null;

      Ember.set(this, 'label', options ? options.label : this.option.label);
      Ember.set(this, 'groupings', options ? options.groupings : '');

      this.closeSelect();
    },
    closeSelect: function closeSelect() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.showSettings) {
          _this.select.actions.close();
        }
      });
    },


    actions: {
      update: function update(field, e) {
        Ember.set(this, field, e.target.value);

        this.extra.onUpdate({
          dataFieldId: this.option.id,
          label: this.label,
          groupings: this.groupings
        });
      }
    }
  });
});