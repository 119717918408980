define('command-center/mirage/factories/channel-type', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: _emberCliMirage.faker.list.cycle('display', 'native_video', 'search'),
    label: _emberCliMirage.faker.list.cycle('Display', 'Native Video', 'Search')
  });
});