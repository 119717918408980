define('command-center/validations/audience', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    label: (0, _validators.validatePresence)(true),
    age: (0, _validators.validatePresence)(true),
    language: (0, _validators.validatePresence)(true),
    genderSkew: (0, _validators.validatePresence)(true),
    habits: (0, _validators.validatePresence)(true),
    geoTarget: (0, _validators.validatePresence)(true),
    description: (0, _validators.validatePresence)(true)
  };
});