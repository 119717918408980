define("command-center/validators/date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateDate;
  function validateDate() /*options*/{
    return function () /*key, newValue, oldValue, changes, content*/{
      return true;
    };
  }
});