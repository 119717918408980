define('command-center/helpers/validate-transition', ['exports', 'ember-steps/helpers/validate-transition'], function (exports, _validateTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _validateTransition.default;
    }
  });
  Object.defineProperty(exports, 'validateTransition', {
    enumerable: true,
    get: function () {
      return _validateTransition.validateTransition;
    }
  });
});