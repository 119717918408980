define('command-center/pods/campaigns/campaign/data-streams/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    columns: Ember.computed(function () {
      return [{
        label: 'Data Source',
        valuePath: 'dataSourceLabel'
      }, {
        label: 'Frequency',
        valuePath: 'frequencyLabel'
      }, {
        label: 'Delivery Type',
        valuePath: 'deliveryTypeLabel'
      }, {
        label: 'Email',
        valuePath: 'email',
        width: '35%',
        cellComponent: 'text-select-cell'
      }, {
        label: 'Report Type',
        valuePath: 'typeLabel'
      }, {
        cellComponent: 'campaigns/campaign/data-streams/process-cell'
      }];
    }),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'columns', this.columns);
    },


    actions: {
      showDataStream: function showDataStream(row) {
        this.transitionTo('campaigns.campaign.data-streams.data-stream', Ember.get(row, 'content.id'));
      },
      fetch: function fetch(query) {
        var newQuery = Object.assign({}, query, {
          'filter[campaign_id]': this.modelFor('campaigns/campaign').id,
          include: 'campaign'
        });
        return Ember.get(this, 'store').query('data-stream', newQuery);
      }
    }
  });
});