define('command-center/models/datum', ['exports', 'ember-data', 'command-center/pods/campaigns/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    age: attr('string'),
    ad: attr('string'),
    adGroup: attr('string'),
    browserPlatform: attr('string'),
    campaignName: attr('string'),
    clicks: attr('number'),
    clickThroughConversions: attr('number'),
    conversionName: attr('string'),
    conversionRate: attr('number'),
    conversions: attr('number'),
    cost: attr('number'),
    costConversion: attr('number'),
    country: attr('string'),
    cpa: attr('number'),
    cpc: attr('number'),
    cpcv: attr('number'),
    cpe: attr('number'),
    cpm: attr('number'),
    cpmCost: attr('number'),
    creative: attr('string'),
    ctr: attr('number'),
    cvr: attr('number'),
    date: attr('string'),
    dcpm: attr('number'),
    device: attr('string'),
    deviceType: attr('string'),
    dmaRegion: attr('string'),
    ecpm: attr('number'),
    engagements: attr('number'),
    engagementRate: attr('number'),
    frequency: attr('number'),
    gender: attr('string'),
    impressions: attr('number'),
    influencedVisits: attr('number'),
    keyword: attr('string'),
    linkClicks: attr('number'),
    linkCTR: attr('number'),
    linkCPC: attr('number'),
    measurableImpressions: attr('number'),
    mediaCost: attr('number'),
    matchType: attr('string'),
    mobileCarrier: attr('string'),
    networkType: attr('string'),
    page1: attr('string'),
    page2: attr('string'),
    page3: attr('string'),
    placement: attr('string'),
    platform: attr('string'),
    inventory_type: attr('string'),
    position: attr('number'),
    postComments: attr('number'),
    postEngagement: attr('number'),
    postReactions: attr('number'),
    postShares: attr('number'),
    profitMargin: attr('number'),
    reach: attr('number'),
    region: attr('string'),
    revenue: attr('number'),
    roi: attr('number'),
    spend: attr('number'),
    tapPage1: attr('number'),
    tapPage2: attr('number'),
    timeOfDay: attr('string'),
    timeSpent: attr('number'),
    trueviewCompletedViews: attr('number'),
    vcr: attr('number'),
    videoCompletions: attr('number'),
    video25PctCompletion: attr('number'),
    video50PctCompletion: attr('number'),
    video75PctCompletion: attr('number'),
    video95PctCompletion: attr('number'),
    video100PctCompletion: attr('number'),
    video3SecViews: attr('number'),
    video10SecViews: attr('number'),
    videoPctViewed: attr('number'),
    videoMutes: attr('number'),
    videoPauses: attr('number'),
    viewability: attr('number'),
    viewableImpressions: attr('number'),
    viewThroughConversions: attr('number'),
    visitationRate: attr('number'),

    audience: belongsTo('audience'),
    campaign: belongsTo('campaign'),
    channel: belongsTo('channel'),
    company: belongsTo('company'),
    lineItem: belongsTo('lineItem'),

    inventoryTypeLabels: Ember.computed('inventoryType', function () {
      return this.inventoryType ? this.inventoryType.split(',').map(function (inventoryType) {
        return _constants.default.INVENTORY_TYPES.findBy('value', inventoryType).label;
      }).join(', ') : '';
    })
  });
});