define('command-center/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/action.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/action.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/advertiser.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/advertiser.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/audience.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/audience.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/campaign.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/campaign.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/channel-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/channel-type.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/channel.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/channel.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/company.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/datum.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/datum.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/file.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/file.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/platform.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/platform.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/targeting-strategy.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/targeting-strategy.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/vertical.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/vertical.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/action.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/action.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/audience.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/audience.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/campaign-goal.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/campaign-goal.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/campaign.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/campaign.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/channel-type.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/channel-type.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/channel.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/channel.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/company.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/datum.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/datum.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/permission.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/permission.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/platform.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/platform.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/targeting-strategy.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/targeting-strategy.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/vertical.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/vertical.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});