define('command-center/pods/components/percent-cell/component', ['exports', 'numeral'], function (exports, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    formattedValue: Ember.computed('value', function () {
      return isNaN(Ember.get(this, 'value')) ? '' : (0, _numeral.default)(Ember.get(this, 'value')).format('0.00%');
    })
  });
});