define('command-center/mirage/factories/vertical', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: _emberCliMirage.faker.list.cycle('business', 'technology'),
    label: _emberCliMirage.faker.list.cycle('Business', 'Technology')
  });
});