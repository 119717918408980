define('command-center/pods/components/formatter-cell/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    formattedValue: Ember.computed('this.column.formatter', function () {
      return this.column.formatter(this.value);
    })
  });
});