define('command-center/pods/campaigns/campaign/channels/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var columns = [{
        label: 'Channel',
        valuePath: 'typeLabel'
      }, {
        label: 'Status',
        valuePath: 'stateLabel'
      }, {
        label: 'Pace',
        valuePath: 'pace',
        cellComponent: 'percent-cell'
      }, {
        label: 'Duration',
        cellComponent: 'campaigns/campaign/channels/index/duration-cell',
        width: '17%'
      }, {
        label: 'Actual/Total Units',
        cellComponent: 'campaigns/campaign/channels/index/units-cell',
        width: '20%'
      }, {
        label: 'Cost/Type',
        cellComponent: 'campaigns/campaign/channels/index/cost-cell'
      }, {
        label: 'Actual/Total Budget',
        cellComponent: 'campaigns/campaign/channels/index/budget-cell',
        width: '17%'
      }];

      return Ember.RSVP.hash({
        columns: columns,
        campaign: this.modelFor('campaigns/campaign'),
        channels: this.modelFor('campaigns.campaign.channels'),
        dataFields: this.store.findAll('data-field')
      });
    },
    setupController: function setupController(controller, model) {
      var df = function df(name) {
        return model.dataFields.findBy('name', name);
      };
      var lineChartSettings = {
        component: 'line-chart',
        label: 'Campaign Analytics',
        dimensions: [df('date')],
        metrics: ['clicks', 'ctr', 'revenue', 'influenced_visits'].map(df).filter(function (m) {
          return m;
        }),
        filters: {
          campaign_id: model.campaign.id
        }
      };

      Ember.set(controller, 'lineChartSettings', lineChartSettings);

      this._super.apply(this, arguments);
    },


    actions: {
      showChannel: function showChannel(row) {
        this.transitionTo('campaigns.campaign.channels.channel', Ember.get(row, 'content.id'));
      }
    }
  });
});