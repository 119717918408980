define('command-center/validators/ad-sizes', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validateAdSizes = validateAdSizes;
  function validateAdSizes() {
    return function (key, newValue, oldValue, changes, content) {
      var platform = void 0;
      var _validatePresence = (0, _validators.validatePresence)(true);
      var newPlatform = Ember.get(changes, 'platform');
      var oldPlatform = Ember.get(content, 'platform');

      if (newPlatform && !oldPlatform) {
        platform = newPlatform;
      } else if (!newPlatform && oldPlatform) {
        platform = oldPlatform;
      }

      if (platform && platform.includes('facebook')) {
        return true;
      }

      return _validatePresence(key, newValue);
    };
  }
});