define('command-center/helpers/route-task', ['exports', 'ember-route-task-helper/helpers/route-task'], function (exports, _routeTask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _routeTask.default;
    }
  });
  Object.defineProperty(exports, 'routeTask', {
    enumerable: true,
    get: function () {
      return _routeTask.routeTask;
    }
  });
});