define('command-center/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _faker$name = _emberCliMirage.faker.name,
      _firstName = _faker$name.firstName,
      _lastName = _faker$name.lastName,
      _avatar = _emberCliMirage.faker.image.avatar;
  exports.default = _emberCliMirage.Factory.extend({
    defaultCompanyDomain: '',

    avatar: function avatar() {
      return {
        url: _avatar(),
        filename: this.firstName + ' ' + this.lastName
      };
    },
    firstName: function firstName() {
      return _firstName();
    },
    lastName: function lastName() {
      return _lastName();
    },
    email: function email() {
      return '' + this.firstName.slice(0, 1).toLowerCase() + this.lastName.toLowerCase() + '@' + this.defaultCompanyDomain + '.com';
    }
  });
});