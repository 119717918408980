define('command-center/pods/components/form-item-option-group/option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'pointer',

    tagName: '',

    label: Ember.computed.oneWay('value'),

    selected: Ember.computed('value', 'groupValue', function () {
      return this.groupValue && this.groupValue.includes(this.value);
    }),

    handleUpdate: function handleUpdate(selected) {
      if (this.onUpdate) {
        this.onUpdate(this.value, selected);
      }
    }
  });
});