define('command-center/validations/data-stream', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isAPIDeliveryType(changes, content) {
    return changes.deliveryType === 'api' || !changes.deliveryType && content.deliveryType === 'api';
  }

  var validateHasPlacementID = function validateHasPlacementID() {
    return function (key, newValue, oldValue, changes, content) {
      return isAPIDeliveryType(changes, content) || !!newValue.findBy('placementIdentifier', true) || 'A placement identifier is required';
    };
  };

  var validateHasSampleFile = function validateHasSampleFile() {
    return function (key, newValue, oldValue, changes, content) {
      return isAPIDeliveryType(changes, content) || !!newValue || 'A sample file is required';
    };
  };

  exports.default = {
    dataSource: (0, _validators.validatePresence)(true),
    dataMappingFields: validateHasPlacementID(),
    frequency: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true, gt: 0 })],
    type: (0, _validators.validatePresence)(true),
    sampleFile: validateHasSampleFile()
  };
});