define('command-center/pods/components/form-item-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'pointer',

    tagName: 'label',

    change: function change() {
      if (this.onUpdate) {
        this.onUpdate(this.value, !this.selected);
      }
    }
  });
});