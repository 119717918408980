define('command-center/pods/components/form-item-option-group/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    multiselect: false,

    optionComponent: Ember.computed('multiselect', function () {
      return this.multiselect ? 'form-item-checkbox' : 'form-item-radio';
    }),

    addItem: function addItem(item) {
      return this.value.includes(item) ? this.value : [].concat(_toConsumableArray(this.value), [item]);
    },
    removeItem: function removeItem(item) {
      return this.value.includes(item) ? this.value.filter(function (currentItem) {
        return currentItem !== item;
      }) : this.value;
    },
    handleUpdate: function handleUpdate(item, selected) {
      var newValue = void 0;

      if (this.multiselect) {
        newValue = selected ? this.addItem(item) : this.removeItem(item);
      } else {
        newValue = selected ? item : this.value;
      }

      if (this.onUpdate) {
        this.onUpdate(newValue);
      }
    },
    didInsertElement: function didInsertElement() {
      if (!this.value) {
        Ember.set(this, 'value', this.multiselect ? [] : '');
      }
    }
  });
});