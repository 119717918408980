define('command-center/mirage/factories/company', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _faker$address = _emberCliMirage.faker.address,
      _city = _faker$address.city,
      secondaryAddress = _faker$address.secondaryAddress,
      streetAddress = _faker$address.streetAddress,
      _zipCode = _faker$address.zipCode,
      _state = _faker$address.state,
      _avatar = _emberCliMirage.faker.image.avatar,
      companyName = _emberCliMirage.faker.company.companyName;
  exports.default = _emberCliMirage.Factory.extend({
    avatar: function avatar() {
      return {
        url: _avatar(),
        filename: this.firstName + ' ' + this.lastName
      };
    },
    address1: function address1() {
      return streetAddress();
    },
    address2: function address2() {
      return secondaryAddress();
    },
    city: function city() {
      return _city();
    },
    label: function label() {
      return companyName();
    },
    zipCode: function zipCode() {
      return _zipCode();
    },
    state: function state() {
      return _state();
    },


    default: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(company, server) {
        var admin = company.roles.models.findBy('label', 'Admin');
        var campaignManager = company.roles.models.findBy('label', 'Campaign Manager');
        var domain = company.label.split(' ').joing('').toLowerCase();
        server.create('user', {
          role: admin,
          primaryCompany: company,
          defaultCompanyDomain: domain,
          defaultCompanyId: company.id,
          defaultCompanyLabel: company.label
        });
        server.createList('user', 29, {
          role: campaignManager,
          primaryCompany: company,
          defaultCompanyDomain: domain,
          defaultCompanyId: company.id,
          defaultCompanyLabel: company.label
        });
      }
    }),

    awlogy: (0, _emberCliMirage.trait)({
      label: 'Awlogy',
      address1: '2970 5th Ave.',
      address2: 'Suite 240',
      city: 'San Diego',
      state: 'CA',
      zipCode: '92103',
      afterCreate: function afterCreate(company, server) {
        var admin = company.roles.models.findBy('label', 'Admin');
        var campaignManager = company.roles.models.findBy('label', 'Campaign Manager');
        server.create('user', {
          firstName: 'Awlogy',
          lastName: 'Admin',
          email: 'admin@awlogy.com',
          role: admin,
          primaryCompany: company,
          defaultCompanyId: company.id,
          defaultCompanyLabel: company.label
        });
        server.createList('user', 29, {
          role: campaignManager,
          primaryCompany: company,
          defaultCompanyDomain: 'awlogy',
          defaultCompanyId: company.id,
          defaultCompanyLabel: company.label
        });
      }
    })
  });
});