define('command-center/validations/campaign', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    label: (0, _validators.validatePresence)(true),
    company: (0, _validators.validatePresence)(true),
    vertical: (0, _validators.validatePresence)(true),
    landingPageUrl: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'url' })],
    budget: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ positive: true, gt: 0 })],
    startDate: (0, _validators.validatePresence)(true),
    endDate: (0, _validators.validatePresence)(true),
    targetLocations: (0, _validators.validatePresence)(true),
    type: (0, _validators.validatePresence)(true),
    paceType: (0, _validators.validatePresence)(true),
    channels: (0, _validators.validatePresence)(true),
    pixelTracking: (0, _validators.validatePresence)(true)
    // conversionAction: validatePresence(true),
  };
});