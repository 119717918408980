define('command-center/pods/components/line-item-table/component', ['exports', 'cc-components/pods/components/cc-table/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    store: Ember.inject.service(),

    borderless: true,

    canLoadMore: false,

    indentation: 1,

    columns: Ember.computed(function () {
      var columns = [{
        label: 'INVENTORY TYPE',
        valuePath: 'inventoryTypeLabels'
      }, {
        label: 'AUDIENCE',
        valuePath: 'audience.label'
      }, {
        label: 'AD TYPE',
        valuePath: 'adTypeLabels'
      }, {
        label: 'BUDGET',
        valuePath: 'budget',
        cellComponent: 'currency-cell'
      }, {
        label: 'START DATE',
        valuePath: 'startDate',
        cellComponent: 'date-cell'
      }, {
        label: 'END DATE',
        valuePath: 'endDate',
        cellComponent: 'date-cell'
      }];

      if (this.channel.get('id')) {
        columns.push({
          label: 'Placement Identifier',
          valuePath: 'placementIdentifier',
          cellComponent: 'input-cell',
          save: function save(model) {
            model.save();
          }
        });
      }

      columns.push({
        label: 'Clone Line Item',
        cellComponent: 'line-item-actions-cell',
        align: 'center',
        width: '10%'
      });

      return columns;
    }),

    actions: {
      cloneLineItem: function cloneLineItem(row) {
        var hasManyItems = {};
        var attrs = row.content.toJSON();
        // TODO: Investigate issue w/ toJSON not serializing end/startDate on copied lineItems.
        Ember.setProperties(attrs, {
          endDate: row.content.get('endDate'),
          startDate: row.content.get('startDate')
        });
        row.content.eachRelationship(function (key, relationship) {
          if (relationship.kind == 'belongsTo') {
            attrs[key] = row.content.get(key);
          }
          if (relationship.kind === 'hasMany') {
            hasManyItems[key] = row.content.get(key);
            delete attrs[key];
          }
        });
        var lineItem = this.store.createRecord('line-item', attrs);
        if (Object.keys(hasManyItems).length > 0) {
          Object.keys(hasManyItems).forEach(function (key) {
            hasManyItems[key].forEach(function (item) {
              Ember.get(lineItem, key).pushObject(item);
            });
          });
        }
      }
    }
  });
});