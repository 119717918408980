define('command-center/pods/campaigns/index/table/component', ['exports', 'command-center/pods/campaigns/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),

    columns: Ember.computed('currentUser.user', function () {
      var statusColumn = {
        label: 'Status',
        valuePath: 'stateLabel',
        width: '13%'
      };

      if (this.currentUser.hasPermission('update', 'campaign')) {
        statusColumn = _extends({}, statusColumn, {
          options: _constants.default.CAMPAIGN_STATES,
          cellComponent: 'select-cell',
          update: function update(row, _ref) {
            var value = _ref.value;

            row.content.set('state', value);
            row.content.save();
          }
        });
      }

      return [{
        label: 'Item',
        valuePath: 'label',
        width: '25%'
      }, statusColumn, {
        label: 'Pace',
        valuePath: 'pace',
        cellComponent: 'percent-cell',
        width: '6%'
      }, {
        label: 'Budget',
        cellComponent: 'dashboard/budget-cell'
      }, {
        label: 'Units',
        cellComponent: 'dashboard/units-cell'
      }, {
        label: 'Start',
        valuePath: 'startDate',
        cellComponent: 'date-cell',
        width: '12%'
      }, {
        label: 'End',
        valuePath: 'endDate',
        cellComponent: 'date-cell',
        width: '12%'
      }];
    })
  });
});