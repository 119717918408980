define('command-center/pods/components/better-link-to/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    classNameBindings: ['disabledWhen:moon-gray:mid-gray'],

    _isActive: function _isActive() {
      var isActive = Ember.get(this, 'current-when') === Ember.get(this, '_routing.currentRouteName');
      return isActive || this._super.apply(this, arguments);
    },
    click: function click(e) {
      if (!this.disabledWhen && this.onClick) {
        e.stopPropagation();
        e.preventDefault();
        this.onClick();
      }
    }
  });
});