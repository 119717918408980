define('command-center/pods/campaigns/campaign/channels/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var campaign_id = this.modelFor('campaigns/campaign').id;
      return this.store.query('channel', {
        'filter[campaign_id]': campaign_id,
        'include': 'line_items'
      });
    }
  });
});