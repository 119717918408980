define('command-center/pods/campaigns/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    model: function model() {
      return {
        companies: this.store.query('company', {
          'filter[type]': 'advertiser',
          include: 'campaigns'
        })
      };
    },


    actions: {
      addAdvertiser: function addAdvertiser() {
        this.transitionTo('advertisers.detail', 'new');
      },
      editAdvertiser: function editAdvertiser(company) {
        this.transitionTo('advertisers.detail', company);
      },
      showCampaign: function showCampaign(row) {
        this.transitionTo('campaigns.campaign', Ember.get(row, 'content'));
      }
    }
  });
});