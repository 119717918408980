define('command-center/pods/components/form-item-svg-hexagon-option/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':pa4', 'disabled::pointer', ':tc', ':br3', ':ba', ':b--light-gray', 'selected:bg-blue:bg-white', 'selected:white', 'selected:shadow-6'],

    abbreviation: Ember.computed('label', function () {
      return this.label.split(' ').map(function (word, index) {
        return index ? word[0].toLowerCase() : word[0];
      }).join('');
    }),

    click: function click() {
      if (this.onUpdate) {
        this.onUpdate(!this.selected);
      }
    }
  });
});