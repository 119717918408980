define('command-center/mirage/factories/channel', ['exports', 'ember-cli-mirage', 'command-center/pods/campaigns/constants', 'moment'], function (exports, _emberCliMirage, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _faker$list, _faker$list2, _faker$list3, _faker$list4, _faker$list5;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberCliMirage.Factory.extend({
    inventoryType: (_faker$list = _emberCliMirage.faker.list).random.apply(_faker$list, _toConsumableArray(_constants.default.INVENTORY_TYPES.mapBy('value'))),
    solution: (_faker$list2 = _emberCliMirage.faker.list).random.apply(_faker$list2, _toConsumableArray(_constants.default.SOLUTIONS)),
    costType: (_faker$list3 = _emberCliMirage.faker.list).random.apply(_faker$list3, _toConsumableArray(_constants.default.COST_TYPES)),
    state: (_faker$list4 = _emberCliMirage.faker.list).random.apply(_faker$list4, _toConsumableArray(_constants.default.CHANNEL_STATES.mapBy('value'))),
    type: (_faker$list5 = _emberCliMirage.faker.list).random.apply(_faker$list5, _toConsumableArray(_constants.default.CHANNEL_TYPES.mapBy('value'))),
    adUnits: function adUnits() {
      var randomChannel = _emberCliMirage.faker.list.random(Object.keys(_constants.default.AD_UNITS_BY_CHANNEL));
      return _constants.default.AD_UNITS_BY_CHANNEL[randomChannel];
    },
    cost: function cost() {
      return Math.random() * 5;
    },
    actualUnits: function actualUnits() {
      return this.units * Math.random() / 2;
    },
    units: function units() {
      return Math.random() * 3000000 + 1000000;
    },
    spend: function spend() {
      return this.budget * Math.random() / 2;
    },
    budget: function budget() {
      return Math.round(Math.random() * 150) * 1000 + 50000;
    },
    pace: function pace() {
      return Math.random();
    },
    startDate: function startDate() {
      var date = _emberCliMirage.faker.date.past();
      return (0, _moment.default)(date.toISOString()).format('YYYY-MM-DD');
    },
    endDate: function endDate() {
      var date = _emberCliMirage.faker.date.future();
      return (0, _moment.default)(date.toISOString()).format('YYYY-MM-DD');
    }
  });
});