define('command-center/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CONSTANTS = {
    CONFIRMATION: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }]
  };

  exports.default = CONSTANTS;
});