define('command-center/ember-composable-form/tests/app.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | app');

  QUnit.test('app/pods/components/composable-form/base-element/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/base-element/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/checkbox/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/checkbox/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/datepicker/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/datepicker/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/error/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/error/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/fieldset/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/fieldset/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/fieldset/legend/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/fieldset/legend/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/file/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/file/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/hint/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/hint/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/input/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/input/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/input/field/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/input/field/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/label/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/label/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/multi-select/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/multi-select/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/option-group/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/option-group/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/option-group/option/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/option-group/option/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/progress-bar/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/progress-bar/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/progress-bar/item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/progress-bar/item/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/radio-button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/radio-button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/row/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/row/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/section/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/section/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/section/subtitle/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/section/subtitle/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/select/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/select/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/selected-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/selected-item/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/textarea/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/textarea/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/textarea/field/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/textarea/field/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/composable-form/typeahead/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/composable-form/typeahead/component.js should pass ESLint\n\n');
  });
});