define('command-center/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    avatar: attr(),
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    // This is a temporary hack until meta is properly supported in Ember Data
    meta: attr(), // eslint-disable-line ember/no-empty-attrs

    primaryCompany: belongsTo('company'),
    role: belongsTo('role'),
    permissions: hasMany('permission'),

    label: Ember.computed('firstName', 'lastName', function () {
      return this.firstName + ' ' + this.lastName;
    })
  });
});