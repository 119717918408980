define('command-center/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // Add your transitions here, like:
    this.transition(this.matchSelector('#modal-backdrop'), this.toValue(function (toValue, fromValue) {
      return toValue === null || fromValue === null;
    }), this.use('fade'));

    this.transition(this.matchSelector('#modal-dialog'), this.toValue(function (toValue, fromValue) {
      return toValue === null || fromValue === null;
    }), this.use('fade'));

    this.transition(this.matchSelector('#modal-dialog'), this.toValue(function (toValue, fromValue) {
      return toValue && fromValue && toValue > fromValue;
    }), this.use('to-left'), this.reverse('to-right'));

    this.transition(this.hasClass('modal'), this.use('fade'));
  };
});