define('command-center/pods/components/action-items/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    columns: Ember.computed(function () {
      return [{
        label: 'Item',
        valuePath: 'label'
      }, {
        label: 'Advertiser',
        valuePath: 'advertiser.label'
      }, {
        label: 'Campaign',
        valuePath: 'campaign.label'
      }, {
        label: 'Confirm By',
        valuePath: 'confirmBy',
        cellComponent: 'date-cell'
      }, {
        label: 'Requested By',
        valuePath: 'creator',
        cellComponent: 'user-cell'
      }, {
        label: 'Action',
        valuePath: 'type',
        cellComponent: 'action-cell'
      }];
    }),

    actions: {
      fetch: function fetch() {
        return this.store.query('action', { include: 'campaign,advertiser,creator' });
      }
    }
  });
});