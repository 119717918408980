define('command-center/pods/components/channel-dashboard/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    tagName: '',

    store: Ember.inject.service(),

    dataFields: Ember.computed(function () {
      return this.store.findAll('data-field');
    }),

    items: Ember.computed('dataFields.@each', 'filters', 'dashboard.items.@each', function () {
      var _this = this;

      var df = function df(name) {
        return _this.dataFields.findBy('name', name);
      };
      return this.dashboard.items && this.dataFields.length ? this.dashboard.items.map(function (dashboardItem, index) {
        var fields = {
          metrics: (dashboardItem.metrics || []).map(df),
          dimensions: (dashboardItem.dimensions || []).map(df)
        };

        return _extends({
          index: index
        }, dashboardItem, fields, {
          filters: _extends({}, _this.filters, dashboardItem.filters)
        });
      }) : null;
    }),

    move: function move(collection, from, to) {
      return from < to ? collection.slice(0, from).concat(collection.slice(from + 1, to + 1), [collection[from]], collection.slice(to + 1, collection.length)) : collection.slice(0, to).concat([collection[from]], collection.slice(to, from), collection.slice(from + 1, collection.length));
    },


    actions: {
      addDashboardItem: function addDashboardItem() {
        Ember.set(this, 'dashboard.items', (this.dashboard.items || []).concat([{
          columns: 12
        }]));

        this.dashboard.save();
      },
      removeDashboardItem: function removeDashboardItem(index) {
        Ember.set(this, 'dashboard.items', this.dashboard.items.filter(function (item, index_) {
          return index_ !== index;
        }));

        this.dashboard.save();
      },
      updateDashboardItem: function updateDashboardItem(index, newDashboardItem) {
        var newIndex = index;
        var newDashboardItemObj = Ember.get(newDashboardItem, 'changes').reduce(function (obj, _ref) {
          var key = _ref.key,
              value = _ref.value;

          return _extends(_defineProperty({}, key, value), obj);
        }, {});

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(newDashboardItemObj)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ref2 = _step.value;

            var _ref3 = _slicedToArray(_ref2, 2);

            var key = _ref3[0];
            var value = _ref3[1];

            if (['dimensions', 'metrics'].includes(key)) {
              newDashboardItemObj[key] = value.mapBy('name');
            } else if (key === 'index') {
              newIndex = parseInt(value);
              delete newDashboardItemObj[key];
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        if (index !== newIndex) {
          Ember.set(this, 'dashboard.items', this.move(this.dashboard.items, index, newIndex));
        }

        Ember.set(this, 'dashboard.items', this.dashboard.items.map(function (dashboardItem, currentIndex) {
          return currentIndex === newIndex ? _extends({}, dashboardItem, newDashboardItemObj) : dashboardItem;
        }));

        this.dashboard.save();
      }
    }
  });
});