define('command-center/mirage/factories/targeting-strategy', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: _emberCliMirage.faker.list.cycle('prospecting', 'retargeting'),
    label: _emberCliMirage.faker.list.cycle('Prospecting', 'Retargeting')
  });
});