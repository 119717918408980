define('command-center/pods/campaigns/campaign/edit/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'command-center/validations/campaign'], function (exports, _authenticatedRouteMixin, _campaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        campaign: this.modelFor('campaigns.campaign'),
        validations: _campaign.default
      });
    },


    actions: {
      cancel: function cancel() {
        this.transitionTo('campaigns.campaign', this.modelFor('campaigns.campaign'));
      },
      saveCampaign: function saveCampaign(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save();
          }
          throw 'Not valid';
        }).then(function () {
          _this.transitionTo('campaigns');
          Ember.set(_this, 'controller.campaign', null);
        });
      }
    }
  });
});