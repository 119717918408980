define('command-center/pods/files/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    columns: Ember.computed(function () {
      [{
        valuePath: 'type',
        width: '1vw',
        cellComponent: 'file-icon-cell'
      }, {
        label: 'Name',
        valuePath: 'name',
        width: '25vw'
      }, {
        label: 'Modified',
        valuePath: 'modified',
        width: '17vw'
      }, {
        label: 'Members',
        valuePath: 'members',
        width: '17vw'
      }, {
        label: 'Actions',
        width: '15vw',
        cellComponent: 'file-action-cell'
      }];
    }),

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'columns', this.columns);
    },


    actions: {
      fetch: function fetch(query) {
        return Ember.get(this, 'store').query('file', query);
      }
    }
  });
});