define('command-center/services/data-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.data = this.store.findAll('data-field');
      this.find = function (name) {
        return _this.data.findBy('name', name);
      };
    }
  });
});