define('command-center/admin/tests/app.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | app');

  QUnit.test('app/pods/components/full-screen-modal/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/full-screen-modal/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/services/current-company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/services/current-company.js should pass ESLint\n\n');
  });
});