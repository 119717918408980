define('command-center/router', ['exports', 'command-center/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('reset-password', function () {});
    this.mount('admin');
    this.route('dashboard', { path: '/' });
    this.route('files');
    this.route('campaigns', function () {
      this.route('campaign', { path: '/:campaign_id' }, function () {
        this.route('channels', function () {
          this.route('channel', { path: '/:channel_id' });
        });
        this.route('data-streams', function () {
          this.route('data-stream', { path: '/:data_stream_id' });
        });
        this.route('edit');
      });
      this.route('new');
    });
    this.route('freestyle');
    this.route('advertisers', function () {
      this.route('detail', { path: '/:company_id' });
    });
  });

  exports.default = Router;
});