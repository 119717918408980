define('command-center/pods/campaigns/campaign/channels/index/cost-cell/component', ['exports', 'numeral', 'command-center/pods/campaigns/campaign/channels/index/cost-cell/template'], function (exports, _numeral, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    formattedCost: Ember.computed('row', function () {
      return (0, _numeral.default)(Ember.get(this.row, 'cost')).format('$0,0.00');
    })
  });
});