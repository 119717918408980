define('command-center/pods/channels/channel-form/component', ['exports', 'cc-components/pods/components/cc-composable-form/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    stepCount: 2,
    formTitle: 'Create New Channel',

    formItemSteps: Ember.computed(function () {
      return Ember.A([[{
        type: 'text',
        name: 'label',
        label: 'What channel would you like to add?',
        placeholder: 'Enter here',
        required: true,
        class: 'form-item'
      }, {
        type: 'custom',
        name: 'inventory_types',
        label: 'What inventory types would you like to target?',
        component: 'form-power-select-multiple',
        optionsModel: 'inventory_type',
        required: true,
        class: 'form-item'
      }], [{
        type: 'text',
        name: 'targetInventory',
        label: 'What inventory would you like to target?',
        placeholder: 'Enter here',
        required: true,
        class: 'form-item'
      }, {
        type: 'custom',
        name: 'advertiserWhitelist',
        label: 'Upload advertiser whitelist.',
        placeholder: 'Click to upload advertiser whitelist',
        component: 'form-file-upload',
        required: false,
        class: 'form-item'
      }], [{
        type: 'text',
        name: 'brandSafety',
        label: 'Select brand safety settings',
        placeholder: 'Enter here',
        required: true,
        class: 'form-item'
      }], [{
        type: 'text',
        name: 'primaryKpi',
        label: 'What is your primary KPI?',
        placeholder: 'Enter here',
        required: true,
        class: 'form-item'
      }]]);
    })
  });
});