define('command-center/models/data-field', ['exports', 'ember-data', 'moment', 'numeral'], function (exports, _emberData, _moment, _numeral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    name: attr('string'),
    label: attr('string'),
    description: attr('string'),
    valueType: attr('string'),
    type: attr('string'),

    formatter: Ember.computed('name', 'valueType', function () {
      // const customFormatters = {
      //   targeting_strategy: value =>
      //     value
      //       ? CONSTANTS.TARGETING_STRATEGY_TYPES.findBy('value', value).label
      //       : '',
      // };
      return (
        // customFormatters[this.name] ||
        {
          date: function date(value) {
            return (0, _moment.default)(value).format('MM/DD');
          },
          percentage: function percentage(value) {
            return (0, _numeral.default)(value).format('0.00%');
          },
          integer: function integer(value) {
            return (0, _numeral.default)(value).format('0,0');
          },
          decimal: function decimal(value) {
            return (0, _numeral.default)(value).format('0,0.00');
          },
          currency: function currency(value) {
            return (0, _numeral.default)(value).format('$0,0.00');
          },
          relationship: function relationship(value) {
            return Ember.get(value, 'label') || Ember.get(value, 'typeLabel') || Ember.get(value, 'name') || Ember.get(value, 'type');
          }
        }[this.valueType] || function (value) {
          return value;
        }
      );
    }),

    reducer: Ember.computed('name', 'valueType', function () {
      var sum = function sum(total, value) {
        return total + value;
      };
      var avg = function avg(total, value, index, data) {
        var newTotal = total + value;
        if (index === data.length - 1) {
          return newTotal / parseFloat(data.length);
        }
        return newTotal;
      };

      return {
        cpm: avg,
        cpc: avg,
        cpcv: avg,
        cpm_cost: avg,
        ecpm: avg,
        dcpm: avg,
        cpe: avg,
        cpa: avg
      }[this.name] || {
        percentage: avg
      }[this.valueType] || sum;
    })
  });
});