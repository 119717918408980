define('command-center/pods/components/user-can/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    currentUser: Ember.inject.service(),

    isAllowed: Ember.computed('currentUser.user', 'action', 'object', function () {
      return this.currentUser.hasPermission(this.action, this.object);
    })
  }).reopenClass({
    positionalParams: ['action', 'object']
  });
});