define('command-center/pods/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),

    showAppView: Ember.computed('router.currentRouteName', function () {
      var _this = this;

      return !['login', 'reset-password'].find(function (path) {
        return _this.router.currentRouteName.includes(path);
      });
    })
  });
});