define('command-center/pods/campaigns/campaign/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    dateRange: Ember.inject.service(),

    model: function model(_ref) {
      var campaign_id = _ref.campaign_id;

      return this.store.findRecord('campaign', campaign_id, {
        include: 'channels'
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      Ember.set(controller, 'dateRangeService', this.dateRange);
    },


    actions: {
      addDataStream: function addDataStream() {
        this.transitionTo('campaigns.campaign.data-streams.data-stream', 'new');
      },
      addChannel: function addChannel() {
        this.transitionTo('channels.channel', 'new');
      },
      editCampaign: function editCampaign(campaign) {
        this.transitionTo('campaigns.campaign.edit', campaign);
      },
      showCampaignChannel: function showCampaignChannel() {
        var channel = this.store.peekAll('channel').findBy('campaign.id', this.modelFor('campaigns.campaign').id);

        this.transitionTo('campaigns.campaign.channels.channel', channel.id);
      }
    }
  });
});