define('command-center/pods/freestyle/controller', ['exports', 'ember-freestyle/controllers/freestyle'], function (exports, _freestyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _freestyle.default.extend({
    emberFreestyle: Ember.inject.service(),

    lineItem: Ember.computed(function () {
      return this.store.createRecord('lineItem');
    }),

    formItemCheckboxSelected: false,

    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    selectedCountry: ['United States'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    expandableTableColumns: Ember.computed(function () {
      return [{
        label: 'Item',
        valuePath: 'advertiserLabel'
      }, {
        label: 'Status',
        valuePath: 'state'
      }, {
        label: 'Pace',
        valuePath: 'pace'
      }, {
        label: 'Budget',
        valuePath: 'budget'
      }, {
        label: 'Units',
        valuePath: 'units'
      }, {
        label: 'Start',
        valuePath: 'startDate'
      }, {
        label: 'End',
        valuePath: 'endDate'
      }];
    }),

    datasets: Ember.computed(function () {
      return [{ formatter: function formatter(x) {
          return x;
        } }];
    }),

    devicePerformanceData: Ember.computed(function () {
      return {
        labels: ['Desktop', 'Tablet', 'Mobile'],
        datasets: [{
          label: 'Impressions',
          labelValue: '2986',
          data: [12000, 22000, 10000]
        }, {
          label: 'Video Views',
          labelValue: '0.09%',
          data: [15000, 33000, 15000]
        }, {
          label: 'VCR',
          labelValue: '$280,535',
          data: [20000, 19000, 19000]
        }]
      };
    }),

    deviceUsageData: Ember.computed(function () {
      return {
        labels: ['iOS', 'Android', 'Blackberry', 'Symbian', 'Others'],
        datasets: [{
          label: 'Device Usage',
          data: [30, 10, 20, 15, 30]
        }]
      };
    }),

    multiOptions: Ember.computed(function () {
      return [{ label: 'Foo', option: 'bar' }, { label: 'Baz', option: 'oof' }, { label: 'Rab', option: 'zab' }];
    }),

    /* BEGIN-FREESTYLE-USAGE fp--notes
    ### A few notes regarding freestyle-palette
     - Accepts a colorPalette POJO like the one found in the freestyle.js blueprint controller
    - Looks very nice
     And another thing...
     ###### Markdown note demonstrating prettified code
     ```
    import Ember from 'ember';
     export default Ember.Component.extend({
      // ...
      colorPalette: {
        'primary': {
          'name': 'cyan',
          'base': '#00bcd4'
        },
        'accent': {
          'name': 'amber',
          'base': '#ffc107'
        }
      }
      // ...
    });
    ```
    END-FREESTYLE-USAGE */
    colorPalette: Ember.computed(function () {
      return {
        primary: {
          name: 'cyan',
          base: '#00bcd4'
        },
        accent: {
          name: 'amber',
          base: '#ffc107'
        },
        secondary: {
          name: 'greyish',
          base: '#b6b6b6'
        },
        foreground: {
          name: 'blackish',
          base: '#212121'
        },
        background: {
          name: 'white',
          base: '#ffffff'
        }
      };
    }),

    actions: {
      expandableTableFetch: function expandableTableFetch() {
        return [{
          advertiserLabel: 'Nike'
        }, {
          advertiserLabel: 'Under Armour'
        }, {
          advertiserLabel: 'Puma'
        }];
      },
      expandableTableExpandedFetch: function expandableTableExpandedFetch() {
        return [{
          advertiserLabel: 'Campaign 1',
          state: 'Pausing',
          pace: '80%',
          budget: '$29,799 | $429,000',
          units: '2,894,988 | 3,000,000',
          startDate: '6/7/2018',
          endDate: '9/28/2018'
        }, {
          advertiserLabel: 'Campaign 2',
          state: 'Pausing',
          pace: '80%',
          budget: '$29,799 | $429,000',
          units: '2,894,988 | 3,000,000',
          startDate: '6/7/2018',
          endDate: '9/28/2018'
        }, {
          advertiserLabel: 'Campaign 3',
          state: 'Pausing',
          pace: '80%',
          budget: '$29,799 | $429,000',
          units: '2,894,988 | 3,000,000',
          startDate: '6/7/2018',
          endDate: '9/28/2018'
        }];
      },
      lineItemUpdateChannel: function lineItemUpdateChannel(channelType, lineItem) {
        var channel = this.store.peekAll('channel').findBy('type', channelType);
        if (!channel) {
          channel = this.store.createRecord('channel', {
            type: channelType
          });
        }
        Ember.set(lineItem, 'channel', channel);
        Ember.set(lineItem, 'adUnits', '');
      },
      lineItemSubmit: function lineItemSubmit() {},
      lineItemCancel: function lineItemCancel() {}
    }
  });
});