define('command-center/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    facebookAdvertiserId: attr('string'),
    avatar: attr(),
    address1: attr('string'),
    address2: attr('string'),
    city: attr('string'),
    default: attr('boolean'),
    label: attr('string'),
    zipCode: attr('string'),
    state: attr('string'),
    type: attr('string'),

    parentCompany: belongsTo('company', { inverse: 'childCompanies' }),
    childCompanies: hasMany('company', { inverse: 'parentCompany' }),
    users: hasMany('user'),
    campaigns: hasMany('campaigns'),

    firstInitial: Ember.computed(function () {
      return this.label[0];
    })
  });
});