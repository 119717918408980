define('command-center/pods/components/pie-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    classNames: 'pie-chart flex flex-column br3 bg-white',

    _data: Ember.computed('data', '_visibleFields', function () {
      return this._hasNoData() ? null : _extends({}, this.data, {
        datasets: this.data.datasets.map(function (dataset) {
          return _extends({}, dataset, {
            backgroundColor: ['#BD10E0', '#F67B59', '#50E3C2', '#D0021B', '#04A9F4', '#F5A623', '#FF006F']
          });
        })
      });
    }),

    _defaultOptions: Ember.computed(function () {
      var options = this.options || {};

      return _extends({
        maintainAspectRatio: false,
        legend: {
          position: 'right'
        },
        tooltips: {
          callbacks: {
            title: function title(tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label;
            },
            label: function label(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].formattedData[tooltipItem.index];
            }
          }
        }
      }, options);
    }),

    _hasNoData: function _hasNoData() {
      return !Ember.get(this, 'data.datasets.length') || !this.data.datasets.reduce(function (total, dataset) {
        return total + dataset.data.length;
      }, 0);
    }
  });
});