define('command-center/mirage/models/company', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    parentCompany: (0, _emberCliMirage.belongsTo)('company', { inverse: 'childCompanies' }),
    childCompanies: (0, _emberCliMirage.hasMany)('company', { inverse: 'parentCompany' }),
    users: (0, _emberCliMirage.hasMany)('user', { inverse: 'companies' }),
    roles: (0, _emberCliMirage.hasMany)(),
    campaigns: (0, _emberCliMirage.hasMany)()
  });
});