define('command-center/services/current-company', ['exports', 'admin/services/current-company'], function (exports, _currentCompany) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _currentCompany.default;
    }
  });
});