define('command-center/pods/campaigns/campaign/channels/index/line-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    dateRange: Ember.inject.service(),

    store: Ember.inject.service(),

    fields: Ember.computed(function () {
      return ['clicks', 'ctr', 'revenue', 'influencedVisits'];
    }),

    data: Ember.computed('dateRange.filter', function () {
      return this.store.query('datum', _extends({
        'fields[data]': ['date'].concat(this.fields).map(Ember.String.underscore).join(','),
        'filter[campaign_id]': this.campaignId
      }, this.dateRange.filter, {
        group_by: 'date'
      }));
    })
  });
});