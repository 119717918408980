define('command-center/models/channel', ['exports', 'ember-data', 'command-center/pods/campaigns/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    pace: attr('number'),
    startDate: attr('string'),
    endDate: attr('string'),
    spend: attr('number'),
    budget: attr('number'),
    cost: attr('number'),
    costType: attr('string'),
    actualUnits: attr('number'),
    units: attr('number'),
    state: attr('string'),
    type: attr('string'),

    campaign: belongsTo('campaign'),
    lineItems: hasMany('line-item', {
      serialize: true,
      embed: true
    }),

    icon: Ember.computed('type', function () {
      return {
        desktop_display: 'desktop',
        mobile_display: 'mobile',
        cross_device_display: 'tablet',
        desktop_video: 'video',
        mobile_video: 'video',
        cross_device_video: 'video',
        desktop_high_impact: 'desktop',
        mobile_high_impact: 'mobile',
        cross_device_high_impact: 'tablet',
        native_display: 'laptop',
        native_video: 'video',
        paid_search: 'search',
        paid_social: 'share-alt',
        steaming_audio: 'headphones',
        connected_tv: 'tv',
        added_value: 'hand-holding-usd'
      }[this.type] || 'image';
    }),

    iconPrefix: Ember.computed('icon', function () {
      return {
        // google: 'fab',
      }[this.icon] || 'fas';
    }),

    typeLabel: Ember.computed('type', function () {
      return _constants.default.CHANNEL_TYPES.findBy('value', this.type).label;
    }),

    stateLabel: Ember.computed('state', function () {
      return _constants.default.CHANNEL_STATES.findBy('value', this.state).label;
    }),

    _budget: Ember.computed('lineItems.@each.budget', function () {
      var lineItems = this.lineItems;
      if (lineItems) {
        return lineItems.reduce(function (totalBudget, lineItem) {
          var budget = lineItem.budget;

          // TODO: Investigate wierd issue where budget is a string.
          // Happens when adding/cloning lineItems in the channels-table
          // during campaign creation.
          if (typeof budget === 'string') {
            budget = parseInt(budget);
          }

          return totalBudget + budget;
        }, 0);
      }
      return 0;
    }),

    _units: Ember.computed('lineItems.@each.units', function () {
      var lineItems = this.lineItems;
      if (lineItems) {
        return lineItems.reduce(function (totalUnits, lineItem) {
          var units = lineItem.units;

          // TODO: Investigate wierd issue where units is a string.
          // Happens when adding/cloning lineItems in the channels-table
          // during campaign creation.
          if (typeof units === 'string') {
            units = parseInt(units);
          }

          return totalUnits + units;
        }, 0);
      }
      return 0;
    })
  });
});