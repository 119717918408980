define('command-center/pods/campaigns/campaign/data-streams/data-stream/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'command-center/validations/data-stream'], function (exports, _authenticatedRouteMixin, _dataStream) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var campaign = this.modelFor('campaigns.campaign');
      if (params.data_stream_id === 'new') {
        if (campaign) {
          return this.store.createRecord('data-stream', {
            campaign: this.modelFor('campaigns.campaign'),
            dataMappingFields: Ember.A()
          });
        } else {
          this.transitionTo('campaigns');
        }
      } else {
        return this.store.findRecord('data-stream', params.data_stream_id, {
          include: 'data_mapping_fields',
          reload: true
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      Ember.setProperties(controller, {
        dataStream: model,
        validations: _dataStream.default
      });
    },


    actions: {
      save: function save(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            return changeset.save();
          }
          throw 'There was an error';
        }).then(function () {
          _this.transitionTo('campaigns.campaign.data-streams', _this.modelFor('campaigns.campaign'));
        }).catch(function () {
          return 'There was an error';
        });
      },
      delete: function _delete() {
        var _this2 = this;

        var dataStream = Ember.get(this, 'controller.dataStream');
        var campaign = Ember.get(dataStream, 'campaign');
        dataStream.destroyRecord().then(function () {
          _this2.transitionTo('campaigns.campaign', campaign);
        });
      }
    }
  });
});