define('command-center/admin/tests/addon.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | addon');

  QUnit.test('addon/engine.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/engine.js should pass ESLint\n\n');
  });

  QUnit.test('addon/initializers/responsive.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/initializers/responsive.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/application/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/application/route.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/companies/company-form/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/companies/company-form/component.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/companies/company/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/companies/company/route.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/companies/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/companies/route.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/users/index/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/users/index/route.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/users/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/users/route.js should pass ESLint\n\n');
  });

  QUnit.test('addon/pods/users/user/route.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/pods/users/user/route.js should pass ESLint\n\n');
  });

  QUnit.test('addon/resolver.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/resolver.js should pass ESLint\n\n');
  });

  QUnit.test('addon/routes.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/routes.js should pass ESLint\n\n');
  });

  QUnit.test('addon/services/current-company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/services/current-company.js should pass ESLint\n\n');
  });

  QUnit.test('addon/validations/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/validations/company.js should pass ESLint\n\n');
  });

  QUnit.test('addon/validations/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/validations/user.js should pass ESLint\n\n');
  });
});