define('command-center/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PERMISSIONS = {
    campaign: {
      create: ['create_campaign', 'create_company_campaigns'],
      update: ['update_all_campaigns', 'update_company_campaigns']
    },
    company: {
      create: ['create_any_company', 'create_advertiser_company']
    },
    dataStream: {
      read: ['read_all_data_streams', 'read_company_data_streams'],
      create: ['create_all_data_streams', 'create_company_data_streams']
    }
  };

  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    raven: Ember.inject.service(),
    promise: null,

    load: function load() {
      var _this = this;

      if (Ember.get(this, 'promise')) {
        return Ember.get(this, 'promise');
      }

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.get(_this, 'session.isAuthenticated')) {
          Ember.get(_this, 'store').queryRecord('user', {
            me: true,
            include: 'primary_company,permissions'
          }).then(function (user) {
            Ember.set(_this, 'currentCompanyId', '' + Ember.get(user, 'primaryCompany.id'));
            Ember.set(_this, 'currentCompany', user.primaryCompany);
            Ember.set(_this, 'user', user);
            _this.get('raven').callRaven('setUserContext', {
              id: user.id,
              name: user.firstName + ' ' + user.lastName,
              email: user.email
            });
            resolve(user);
          }, function (error) {
            reject(error);
          });
        } else {
          reject();
        }
      });

      Ember.set(this, 'promise', promise);

      return promise;
    },
    hasPermission: function hasPermission(action, object) {
      var _this2 = this;

      try {
        return this.user && PERMISSIONS[object][action].find(function (permission) {
          return _this2.user.permissions.findBy('name', permission);
        });
      } catch (e) {
        throw 'Unable to find ' + action + ' ' + object + ' permissions';
      }
    }
  });
});