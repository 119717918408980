define('command-center/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    dataFields: Ember.inject.service(),

    routeAfterAuthentication: 'dashboard',

    model: function model() {
      var hash = {
        sideMenuItems: [{
          route: 'dashboard',
          label: 'Dashboard',
          icon: 'home',
          link: 'dashboard'
        }, {
          route: 'campaigns',
          label: 'Advertisers',
          icon: 'th-large',
          link: 'campaigns'
        }],
        userMenuItems: [{ route: 'settings', label: 'Settings' }, { route: 'logout', label: 'Logout' }],
        companies: null
      };

      if (Ember.get(this, 'session.isAuthenticated')) {
        Ember.get(this, 'currentUser').load();
        Ember.get(this, 'dataFields.data');
      }

      return hash;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'session', this.session);
      Ember.set(controller, 'currentUser', this.currentUser);

      Ember.setProperties(controller, Ember.getProperties(model, ['sideMenuItems', 'userMenuItems', 'companies']));
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._super.apply(this, arguments);

      var currentUser = Ember.get(this, 'currentUser');
      var controller = Ember.get(this, 'controller');

      currentUser.load().catch(function () {
        Ember.get(_this, 'session').invalidate();
      });

      if (controller) {
        Ember.set(controller, 'currentUser', currentUser);
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      this._super.apply(this, arguments);
      this.transitionTo('login');
    },


    actions: {
      visit: function visit(route) {
        // TODO: Transition to route
        alert(route);
      },
      createNewCampaign: function createNewCampaign() {
        this.transitionTo('campaigns.new');
      },
      invalidateSession: function invalidateSession() {
        Ember.get(this, 'session').invalidate();
      }
    }
  });
});