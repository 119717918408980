define('command-center/pods/dashboard/units-cell/component', ['exports', 'numeral', 'command-center/pods/dashboard/units-cell/template'], function (exports, _numeral, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    formattedActualUnits: Ember.computed('row', function () {
      return (0, _numeral.default)(Ember.get(this.row, 'actualUnits')).format('0,0');
    }),

    formattedUnits: Ember.computed('row', function () {
      return (0, _numeral.default)(Ember.get(this.row, 'units')).format('0,0');
    }),

    showUnits: Ember.computed('row', function () {
      return Ember.get(this.row, 'actualUnits') != null || Ember.get(this.row, 'units') != null;
    })
  });
});