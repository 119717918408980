define('command-center/mirage/factories/action', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    label: _emberCliMirage.faker.list.cycle('Allocate $5k from social to video', 'Confirm pixel placements'),
    confirmBy: function confirmBy() {
      return _emberCliMirage.faker.date.future();
    },

    type: _emberCliMirage.faker.list.cycle('approval', 'confirmation'),

    creator: (0, _emberCliMirage.association)()
  });
});