define('command-center/pods/campaigns/campaign/data-streams/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CONSTANTS = {
    DATA_SOURCES: [{ value: 'dcm', label: 'DCM' }, { value: 'facebook', label: 'Facebook' }],
    DELIVERY_TYPES: [{ value: 'email', label: 'Email' }, { value: 'manual', label: 'Manual' }, { value: 'api', label: 'API' }],
    FREQUENCIES: [{ value: 1, label: 'Hourly' }, { value: 24, label: 'Daily' }, { value: 144, label: 'Weekly' }],
    TYPES: [{ value: 'campaign', label: 'Campaign' }, { value: 'age', label: 'Age' }]
  };

  exports.default = CONSTANTS;
});