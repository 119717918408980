define('command-center/mirage/factories/campaign', ['exports', 'ember-cli-mirage', 'command-center/pods/campaigns/constants', 'moment'], function (exports, _emberCliMirage, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _faker$list;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberCliMirage.Factory.extend({
    label: function label() {
      return _emberCliMirage.faker.commerce.productName();
    },

    landingPageUrl: _emberCliMirage.faker.name.firstName(),
    spend: function spend() {
      return _emberCliMirage.faker.random.number();
    },
    budget: function budget() {
      return _emberCliMirage.faker.random.number() + this.spend;
    },
    actualUnits: function actualUnits() {
      return _emberCliMirage.faker.random.number();
    },
    units: function units() {
      return _emberCliMirage.faker.random.number() + this.actualUnits;
    },
    kpis: function kpis() {
      return ['IMPS', 'Clicks'];
    },
    startDate: function startDate() {
      var date = _emberCliMirage.faker.date.past();
      return (0, _moment.default)(date.toISOString()).format('YYYY-MM-DD');
    },
    endDate: function endDate() {
      var date = _emberCliMirage.faker.date.future();
      return (0, _moment.default)(date.toISOString()).format('YYYY-MM-DD');
    },

    targetLocations: _emberCliMirage.faker.name.firstName(),
    audienceName: _emberCliMirage.faker.name.firstName(),
    audienceDescription: _emberCliMirage.faker.name.firstName(),
    description: _emberCliMirage.faker.name.firstName(),
    type: _emberCliMirage.faker.name.firstName(),
    performanceGoal: _emberCliMirage.faker.name.firstName(),
    performanceBasedStrategy: _emberCliMirage.faker.random.boolean(),
    trackingPixels: _emberCliMirage.faker.random.boolean(),
    additionalNotes: _emberCliMirage.faker.name.firstName(),
    campaignType: _emberCliMirage.faker.list.cycle('Awareness', 'Direct Response', 'Foot Traffic', 'Website Visits'),
    pace: function pace() {
      return Math.random();
    },

    state: (_faker$list = _emberCliMirage.faker.list).random.apply(_faker$list, _toConsumableArray(_constants.default.CAMPAIGN_STATES.mapBy('value')))
  });
});