define('command-center/pods/components/bar-chart/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Component.extend({
    classNames: 'bar-chart flex flex-column br3 bg-white',

    showLegend: true,

    _data: Ember.computed('data', '_visibleFields', function () {
      return this._hasNoData() ? null : _extends({}, this.data, {
        datasets: this.data.datasets.map(function (dataset) {
          return _extends({}, dataset, {
            backgroundColor: dataset.backgroundColor || ['#189DFF', '#587CFF', '#7C69FF', '#9c69ff', '#b769ff', '#ef69ff', '#b645c3']
          });
        })
      });
    }),

    _defaultOptions: Ember.computed(function () {
      var options = this.options || {};

      return _extends({
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#8F9C9D',
              fontStyle: '300',
              fontSize: '10'
            },
            gridLines: {
              color: '#EDF0F2',
              zeroLineColor: 'rgba(0, 0, 0, 0)'
            }
          }],
          yAxes: [{
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            ticks: {
              min: 0,
              fontColor: '#8F9C9D',
              fontStyle: '300',
              fontSize: '10',
              // TODO: Find a way to make this more generic
              callback: this.datasets[0].formatter
            }
          }]
        },
        legend: {
          display: this.showLegend,
          fullWidth: true,
          labels: {
            boxWidth: 10,
            fontColor: '#898B9E',
            fontStyle: '300'
          }
        },
        tooltips: {
          mode: 'nearest',
          backgroundColor: '#012332',
          callbacks: {
            title: function title(tooltipItem, data) {
              return data.datasets[tooltipItem[0].datasetIndex].label;
            },
            label: function label(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].formattedData[tooltipItem.index];
            }
          }
        }
      }, options);
    }),

    _hasNoData: function _hasNoData() {
      return !Ember.get(this, 'data.datasets.length') || !this.data.datasets.reduce(function (total, dataset) {
        return total + dataset.data.length;
      }, 0);
    }
  });
});