define('command-center/cc-components/tests/app.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | app');

  QUnit.test('app/pods/components/button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-card/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-card/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-composable-form/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-composable-form/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-composable-form/form-actions/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-composable-form/form-actions/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-composable-form/form-actions/form-action-primary/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-composable-form/form-actions/form-action-primary/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-composable-form/form-actions/form-action-secondary/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-composable-form/form-actions/form-action-secondary/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-composable-form/form-actions/form-action/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-composable-form/form-actions/form-action/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-full-screen-modal/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-full-screen-modal/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-header/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-header/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-link-button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-link-button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-menu/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-menu/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-modal/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-modal/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-popover/cc-popover-content/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-popover/cc-popover-content/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-popover/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-popover/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-progress-dot/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-progress-dot/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-row/column/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-row/column/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-row/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-row/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-table/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-table/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/cc-table/loader/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/cc-table/loader/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/ghost-button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/ghost-button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/grid/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/grid/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/grid/grid-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/grid/grid-item/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/link-button/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/link-button/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/modal/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/modal/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/table/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/table/component.js should pass ESLint\n\n');
  });

  QUnit.test('app/pods/components/table/loader/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/pods/components/table/loader/component.js should pass ESLint\n\n');
  });
});