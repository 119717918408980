define('command-center/adapters/application', ['exports', 'ember-data', 'ember-inflector', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-fetch/mixins/adapter-fetch', 'command-center/config/environment'], function (exports, _emberData, _emberInflector, _dataAdapterMixin, _adapterFetch, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  exports.default = JSONAPIAdapter.extend(_adapterFetch.default, _dataAdapterMixin.default, {
    session: Ember.inject.service('session'),

    host: _environment.default.host,

    namespace: _environment.default.namespace,

    headers: Ember.computed(function () {
      var token = this.session.data.authenticated.token;


      if (Ember.testing) {
        return {
          'Authorization': 'JWT ' + token
        };
      }

      if (this.session.isAuthenticated && token) {
        return {
          'Authorization': 'JWT ' + token
        };
      }

      return {};
    }),

    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    }
  });
});