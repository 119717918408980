define('command-center/mirage/factories/file', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.system.commonFileName();
    },

    modified: _emberCliMirage.faker.date.recent(),
    members: _emberCliMirage.faker.list.random('2 members', '3 members', 'Only you'),
    // members() {
    //   return `${faker.name.firstName()}, ${faker.name.firstName()}`;
    // },
    type: _emberCliMirage.faker.list.random(_emberCliMirage.faker.system.commonFileExt(), 'folder')
  });
});