define('command-center/pods/campaigns/new/campaign-form/additional-info/component', ['exports', 'command-center/config/environment', 'command-center/constants'], function (exports, _environment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    pixelTracking: _constants.default.CONFIRMATION,

    fileUploadURL: Ember.computed(function () {
      return _environment.default.host + '/' + _environment.default.namespace + '/request_file_upload';
    })
  });
});