define('command-center/models/targeting-strategy', ['exports', 'ember-data', 'command-center/pods/campaigns/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    type: attr('string'),

    channel: belongsTo('channel'),
    creatives: hasMany('creative'),

    typeLabel: Ember.computed('type', function () {
      var _this = this;

      return _constants.default.TARGETING_STRATEGY_TYPES.find(function (type) {
        return type.value == _this.type;
      }).label;
    })
  });
});