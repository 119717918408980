define('command-center/models/line-item', ['exports', 'ember-data', 'command-center/pods/campaigns/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    spend: attr('number'),
    actualUnits: attr('number'),
    adUnits: attr('string'),
    budget: attr('number'),
    cost: attr('string'),
    costType: attr('string'),
    solution: attr('string'),
    endDate: attr('string'),
    notes: attr('string'),
    pace: attr('number'),
    placementIdentifier: attr('string'),
    placements: attr(),
    inventoryType: attr('string'),
    startDate: attr('string'),
    state: attr('string'),
    units: attr('number'),

    audience: belongsTo('audience'),
    channel: belongsTo('channel'),
    creatives: hasMany('creative'),
    stats: hasMany('datum'),

    inventoryTypeLabels: Ember.computed('inventoryType', function () {
      return this.inventoryType ? this.inventoryType.split(',').map(function (inventoryType) {
        return _constants.default.INVENTORY_TYPES.findBy('value', inventoryType).label;
      }).join(', ') : '';
    })
  });
});