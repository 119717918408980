define('command-center/pods/campaigns/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var CONSTANTS = {
    AGES: ['18-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-64', '65+'],
    AD_UNITS_BY_CHANNEL: {
      desktop_display: ['300x250', '160x600', '300x600', '728x90'],
      mobile_display: ['320x480', '300x50', '320x50'],
      cross_device_display: ['300x250', '160x600', '300x600', '728x90', '320x480', '300x50', '320x50'],
      desktop_video: [':15 ', ':30'],
      mobile_video: [':15 ', ':30'],
      cross_device_video: [':15 ', ':30'],
      desktop_high_impact: ['Custom'],
      mobile_high_impact: ['Custom'],
      cross_device_high_impact: ['Custom'],
      native_display: ['Text + Image'],
      native_video: ['Text + Video'],
      paid_search: ['Text'],
      paid_social: ['Text + Image', ':15', ':30'],
      steaming_audio: [':15', ':30', '300x250'],
      connected_tv: [':15', ':30']
    },
    CAMPAIGN_STATES: [{ value: 'launching', label: 'Launching' }, { value: 'active', label: 'Live' }, { value: 'pending_pause', label: 'Pause Pending' }, { value: 'paused', label: 'Paused' }, { value: 'inactive', label: 'Complete' }, { value: 'archived', label: 'Archived' }],
    CHANNEL_STATES: [{ value: 'launching', label: 'Launching' }, { value: 'active', label: 'Live' }, { value: 'pending_pause', label: 'Pause Pending' }, { value: 'paused', label: 'paused' }, { value: 'inactive', label: 'Complete' }, { value: 'archived', label: 'Archived' }],
    CHANNEL_TYPES: [{ value: 'desktop_display', label: 'Desktop Display' }, { value: 'mobile_display', label: 'Mobile  Display' }, { value: 'cross_device_display', label: 'Cross-Device Display' }, { value: 'desktop_video', label: 'Desktop Video' }, { value: 'mobile_video', label: 'Mobile  Video' }, { value: 'cross_device_video', label: 'Cross-Device Video' }, { value: 'desktop_high_impact', label: 'Desktop High-Impact' }, { value: 'mobile_high_impact', label: 'Mobile  High-Impact' }, { value: 'cross_device_high_impact', label: 'Cross-Device High-Impact' }, { value: 'native_display', label: 'Native Display' }, { value: 'native_video', label: 'Native Video' }, { value: 'paid_search', label: 'Paid Search' }, { value: 'paid_social', label: 'Paid Social' }, { value: 'steaming_audio', label: 'Steaming Audio' }, { value: 'connected_tv', label: 'Connected TV' }, { value: 'added_value', label: 'Added Value' }],
    CONVERSION_ACTIONS: ['Track all actions', 'Track when users submit a form on my homepage', "Don't Track"],
    COST_TYPES: [{ value: 'cpm', label: 'CPM' }, { value: 'cpc', label: 'CPC' }, { value: 'dcpm', label: 'dCPM' }, { value: 'vcpm', label: 'vCPM' }, { value: 'cpe', label: 'CPE' }, { value: 'cpcv', label: 'CPCV' }, { value: 'cpv', label: 'CPV' }, { value: 'cpa', label: 'CPA' }, { value: 'cpi', label: 'CPI' }, { value: 'flat', label: 'Flat' }, { value: 'value_add', label: 'Value Add' }],
    SOLUTIONS: [{ value: 'ip_rooftop', label: 'IP Rooftop' }, {
      value: 'behavioral_interest_targeting',
      label: 'Behavioral / Interest Targeting'
    }, { value: 'retargeting', label: 'Retargeting' }, { value: 'b2b_audience', label: 'B2B Audience' }, { value: 'look_a_like', label: 'Look-a-Like' }, { value: 'smart_prospecting', label: 'Smart Prospecting' }, { value: 'contextual', label: 'Contextual' }, { value: 'historical_footprint', label: 'Historical Footprint' }, { value: 'live_location_targeting', label: 'Live Location Targeting' }, { value: 'keyword', label: 'Keyword' }, {
      value: 'content_article_targeting',
      label: 'Content / Article Targeting'
    }, { value: 'crm_list', label: 'CRM List' }, {
      value: 'competitor_rooftop_targeting',
      label: 'Competitor Rooftop Targeting'
    }, {
      value: 'competitor_historial_rooftop_targeting',
      label: 'Competitor Historial Rooftop Targeting'
    }, {
      value: 'competitor_interest_targeting',
      label: 'Competitor Interest Targeting'
    }, { value: 'audience_accelerator', label: 'Audience Accelerator' }, { value: 'custom_sitelist', label: 'Custom Sitelist' }, { value: 'rich_media', label: 'Rich Media' }],
    LANGUAGES: ['English', 'Arabic', 'Bulgarian', 'Catalan', 'Chinese (simplified)', 'Chinese (traditional)', 'Croatian', 'Czech', 'Danish', 'Dutch', 'Estonian', 'Filipino', 'Finnish', 'French', 'German', 'Greek', 'Hebrew', 'Hindi', 'Hungarian', 'Icelandic', 'Indonesian', 'Italian', 'Japanese', 'Korean', 'Latvian', 'Lithuanian', 'Norwegian', 'Polish', 'Portuguese', 'Romanian', 'Russian', 'Serbian', 'Slovak', 'Slovenian', 'Spanish', 'Swedish', 'Thai', 'Turkish', 'Ukrainian', 'Urdu', 'Vietnamese'],
    PACING: [{ value: 'even', label: 'Even' }, { value: 'ahead', label: 'Ahead' }, { value: 'asap', label: 'ASAP' }],
    PIXEL_TRACKING: ['Yes', 'No'],
    VERTICALS: [{ value: 'arts_and_entertainment', label: 'Arts & Entertainment' }, { value: 'automotive', label: 'Automotive' }, { value: 'business', label: 'Business' }, { value: 'careers', label: 'Careers' }, { value: 'education', label: 'Education' }, { value: 'family_and_parenting', label: 'Family & Parenting' }, { value: 'finance', label: 'Finance' }, { value: 'food_and_drink', label: 'Food & Drink' }, { value: 'health_and_fitness', label: 'Health & Fitness' }, { value: 'hobbies_and_interest', label: 'Hobbies & Interest' }, { value: 'home_and_garden', label: 'Home & Garden' }, {
      value: 'law_government_and_politics',
      label: 'Law, Government, & Politics'
    }, { value: 'lifestyle', label: 'Lifestyle' }, { value: 'other', label: 'Other' }, { value: 'pets', label: 'Pets' }, { value: 'real_estate', label: 'Real Estate' }, { value: 'retail', label: 'Retail' }, { value: 'services', label: 'Services' }, { value: 'society', label: 'Society' }, { value: 'sports', label: 'Sports' }, { value: 'style_and_fashion', label: 'Style & Fashion' }, { value: 'technology', label: 'Technology' }, { value: 'travel', label: 'Travel' }],
    INVENTORY_TYPES: [{ value: 'ad_exchanges', label: 'Ad Exchanges' }, { value: 'ad_networks', label: 'Ad Networks' }, { value: 'custom_sitelist', label: 'Custom Sitelist' }, { value: 'pmp', label: 'PMP' }, { value: 'site_direct', label: 'Site Direct' }, { value: 'youtube', label: 'YouTube' }, { value: 'facebook', label: 'Facebook' }, { value: 'twitter', label: 'Twitter' }, { value: 'snapchat', label: 'Snapchat' }, { value: 'pandora', label: 'Pandora' }, { value: 'spotify', label: 'Spotify' }, { value: 'iheart', label: 'iHeart' }, { value: 'multi_channel', label: 'Multi-channel' }, { value: 'native_exchanges', label: 'Native Exchanges' }],
    CAMPAIGN_FORM_STEPS: {
      'Campaign Info': ['company', 'label', 'vertical', 'landingPageUrl', 'targetLocations'],
      'Campaign Type': ['campaignType'],
      Budget: ['budget', 'startDate', 'endDate', 'paceType'],
      Channels: ['channels'],
      'Additional Info': ['pixelTracking'] // , 'conversionAction'],
    }
  };

  exports.default = CONSTANTS;
});