define('command-center/pods/campaigns/campaign/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect: function redirect(model) {
      this.transitionTo('campaigns.campaign.channels', model);
    }
  });
});