define('command-center/pods/campaigns/campaign/channels/channel/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var channel_id = _ref.channel_id;

      return Ember.RSVP.hash({
        channel: this.store.findRecord('channel', channel_id),
        channels: this.modelFor('campaigns.campaign.channels'),
        dashboard: this.store.queryRecord('dashboard', {
          'filter[channel_id]': channel_id,
          type: 'channel'
        })
      });
    },
    setupController: function setupController(controller, model) {
      if (!model.dashboard) {
        Ember.set(model, 'dashboard', this.store.createRecord('dashboard', {
          channel: model.channel,
          type: 'channel'
        }));
      }
      this._super.apply(this, arguments);
    },


    actions: {
      showChannel: function showChannel(channel) {
        this.transitionTo('campaigns.campaign.channels.channel', channel.id);
      }
    }
  });
});