define('command-center/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var JSONAPISerializer = _emberData.default.JSONAPISerializer;
  exports.default = JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      var keyForAttributeMap = {
        authURLPath: function authURLPath() {
          return 'auth_url_path';
        },
        video25PctCompletion: function video25PctCompletion() {
          return 'video_25_pct_completion';
        },
        video50PctCompletion: function video50PctCompletion() {
          return 'video_50_pct_completion';
        },
        video75PctCompletion: function video75PctCompletion() {
          return 'video_75_pct_completion';
        },
        video95PctCompletion: function video95PctCompletion() {
          return 'video_95_pct_completion';
        },
        video100PctCompletion: function video100PctCompletion() {
          return 'video_100_pct_completion';
        }
      };

      return keyForAttributeMap[attr] ? keyForAttributeMap[attr]() : Ember.String.underscore(attr).toLowerCase();
    },

    keyForRelationship: function keyForRelationship(key) {
      return Ember.String.underscore(key).toLowerCase();
    },

    // This is a temporary hack until meta is properly supported in Ember Data
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload.data.attributes && payload.data.length === 1) {
        payload.data = payload.data[0];
      }
      if (!payload.data.attributes && payload.data.length === 0) {
        payload.data = null;
      }
      if (payload.data) {
        payload.data.attributes.meta = payload.meta;
      }

      return this.normalizeSingleResponse(store, primaryModelClass, payload, id, requestType);
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;

      if (!relationship.options.embed) {
        this._super.apply(this, arguments);
      } else if (this._canSerialize(key)) {
        var belongsTo = snapshot.belongsTo(key);

        var payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key) {
          payloadKey = this.keyForRelationship(key, 'belongsTo', 'serialize');
        }

        json.relationships = _extends({}, json.relationships, _defineProperty({}, payloadKey, {
          data: belongsTo ? _extends({
            id: belongsTo.id
          }, belongsTo.serialize()) : belongsTo
        }));
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;

      if (!relationship.options.embed) {
        this._super.apply(this, arguments);
      } else if (this.shouldSerializeHasMany(snapshot, key, relationship)) {
        var hasMany = snapshot.hasMany(key) || [];
        var payloadKey = this._getMappedKey(key, snapshot.type);

        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
        }

        json.relationships = _extends({}, json.relationships, _defineProperty({}, payloadKey, {
          data: hasMany.map(function (item) {
            return _extends({}, item.serialize().data, {
              id: item.id
            });
          })
        }));
      }
    },
    shouldSerializeHasMany: function shouldSerializeHasMany(snapshot, key, relationship) {
      return relationship.options.serialize;
    }
  });
});