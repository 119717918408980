define('command-center/pods/campaigns/new/campaign-form/budget/component', ['exports', 'command-center/pods/campaigns/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    paceOptions: _constants.default.PACING,

    summaryView: false,

    tagName: ''
  });
});