define('command-center/mirage/factories/role', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var cycle = _emberCliMirage.faker.list.cycle;
  exports.default = _emberCliMirage.Factory.extend({
    label: function label() {
      return cycle('Admin', 'Campaign Manager');
    }
  });
});