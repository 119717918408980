define('command-center/validations/line-item', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // Attrs
    adUnits: (0, _validators.validatePresence)(true),
    budget: (0, _validators.validatePresence)(true),
    cost: (0, _validators.validatePresence)(true),
    costType: (0, _validators.validatePresence)(true),
    solution: (0, _validators.validatePresence)(true),
    endDate: (0, _validators.validatePresence)(true),
    inventoryType: (0, _validators.validatePresence)(true),
    startDate: (0, _validators.validatePresence)(true),
    units: (0, _validators.validatePresence)(true),

    // Relationships
    audience: (0, _validators.validatePresence)(true),
    channel: (0, _validators.validatePresence)(true)
  };
});